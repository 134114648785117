import React, { useState, useContext, useCallback } from 'react';
import { io } from 'socket.io-client';
import { SocketProviderWrapper, SocketContext } from '../../contexts';
import { isAndroid } from '@lendticket/app/helpers/platform';

interface Props {
  children: React.ReactNode;
}

export const useSockets = () => {
  return useContext(SocketContext);
};

const domain = isAndroid ? 'http://10.0.2.2:8000' : 'http://localhost:8000';
const derivedDomain = process.env.NODE_ENV === 'development' ? domain : 'https://api.lendticket.se';
const connectionDomains: { [key: string]: string } = {
  socketLendTickets: `${derivedDomain}/socket/lendtickets`,
  socketLendProfiles: `${derivedDomain}/socket/lendprofiles`,
  socketChat: `${derivedDomain}/socket/chat`,
};

const SocketProvider: React.FC<Props> = ({ children }): React.ReactElement => {
  const [sockets, setSockets] = useState<any>({});

  const createSocket = useCallback((id: string) => {
    const newSocket = io(connectionDomains[id]);
    setSockets((prevSockets: any) => ({
      ...prevSockets,
      [id]: newSocket,
    }));

    return newSocket;
  }, []);

  const removeSocket = (id: string) => {
    if (sockets[id]) {
      sockets[id].disconnect();
      setSockets((prevSockets: any) => {
        const newSockets = { ...prevSockets };
        delete newSockets[id];
        return newSockets;
      });
    }
  };

  return (
    <SocketProviderWrapper value={{ sockets, createSocket, removeSocket }}>
      {children}
    </SocketProviderWrapper>
  );
};

export default SocketProvider;
