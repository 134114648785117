import { useContext, useCallback, useState, useEffect } from 'react';
import { shallowEqual } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import * as routeNames from '@lendticket/app/constants/routeNames';
import api from '@lendticket/app/reducers/slices/api';
import { useAppSelector } from '@lendticket/app/hooks/redux';
import { ticketActions } from '@lendticket/app/reducers/slices/tickets';
import { useAppDispatch } from '@lendticket/app/helpers/redux/store';
import { userSelector } from '@lendticket/app/reducers/slices/user';
import { selectApiTickets } from '@lendticket/app/reducers/slices/api/lendtickets';
import { SocketContext } from 'contexts';
import { isAndroid, isWeb } from '@lendticket/app/helpers/platform';
import { loadingActions } from '@lendticket/app/reducers/slices/loading';
import type { RootState } from '@lendticket/app/helpers/redux';

export const INITIAL_STATE = {
  deletedTickets: {} as Record<string, any>,
  newTickets: {
    tickets: [],
    ticketIds: [],
  },
  liveTickets: {
    allTickets: [],
    tickets: [],
    ticketIds: [],
    searchTickets: [] as { [key: string]: string }[],
    newTicketIds: [],
    isSearching: false,
    isInitiated: false,
    checkForUpdates: false,
    hasTickets: true,
  },
};

const findTicketsSelector = (state: RootState) => ({
  user: userSelector(state),
  apiTickets: selectApiTickets(state),
});

export const useGenerateTickets = () => {
  const { createSocket, removeSocket } = useContext(SocketContext);
  const [liveTickets, setLiveTickets] = useState(INITIAL_STATE.liveTickets);
  const [newTickets, setNewTickets] = useState(INITIAL_STATE.newTickets);
  const [deletedTickets, setDeletedTickets] = useState(INITIAL_STATE.deletedTickets);
  const navigation = useNavigation();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector(findTicketsSelector, shallowEqual);
  const { getChatByTicketIdAndUserId, getLendTickets } = api.endpoints;

  const searchReset = useCallback(() => {
    setLiveTickets({
      ...liveTickets,
      isSearching: false,
      searchTickets: INITIAL_STATE.liveTickets.searchTickets,
      hasTickets: liveTickets.tickets.length > 0,
    });
  }, [liveTickets]);

  const onSwipeAndOnPressAction = useCallback(
    async ({ id }: any) => {
      const params = { ticketId: id, userId: user?.id };
      const { data } = await dispatch(getChatByTicketIdAndUserId.initiate(params));
      const { status: ticketDataStatus, chat } = data ?? {};
      const isTicketOwner =
        chat?.[0]?.createdByUserId === user?.id ||
        liveTickets.tickets
          .filter(({ id: ticketId }) => ticketId === id)
          .some(
            ({ createdByUserId: derivedCreatedByUserId }) => derivedCreatedByUserId === user?.id,
          );

      if (ticketDataStatus === 200) {
        dispatch(loadingActions.setLoadingContent({ isLoading: false }));
        dispatch(ticketActions.setIsTicketOwner(isTicketOwner));
        dispatch(
          ticketActions.setSelectedTicket(
            liveTickets.tickets.filter(({ id: ticketId }) => ticketId === id),
          ),
        );

        if (chat?.[0]?.id) {
          navigation.navigate(routeNames.CHAT, {
            ticketId: id,
            chatId: data?.chat?.[0]?.id,
            userId: user?.id,
          });
        } else {
          navigation.navigate(routeNames.CHAT, {
            ticketId: id,
            userId: user?.id,
          });
        }
      }
    },
    [user, dispatch, getChatByTicketIdAndUserId, liveTickets, navigation],
  );

  /* NOTE: Initial - show first 10 for performance reason */
  const initiate = useCallback(async () => {
    const { refetch } = dispatch(getLendTickets.initiate());
    const { data } = await refetch();
    const { tickets } = data ?? {};

    if (Object.keys(tickets).length > 0) {
      const ticketsFirstTen = [...tickets]
        .sort((a: any, b: any) => b.createdAt - a.createdAt)
        .slice(0, isWeb ? 10 : tickets.length);

      setLiveTickets({
        ...liveTickets,
        allTickets: tickets,
        tickets: ticketsFirstTen,
        ticketIds: ticketsFirstTen.map(({ id }: { id: string }) => id),
        newTicketIds: isAndroid
          ? []
          : ticketsFirstTen.map(({ id }: { id: string }) => id).slice(0, 5),
        hasTickets: tickets?.length > 0,
        isInitiated: true,
        checkForUpdates: false,
      });
    } else {
      setLiveTickets({
        ...INITIAL_STATE.liveTickets,
        isInitiated: true,
        hasTickets: false,
      });
    }
  }, [dispatch, getLendTickets, liveTickets]);

  /* NOTE: Reset and updating behaviour based on new tickets */
  const updateNewTickets = useCallback(
    ({ tickets, newTicketIds }: any) => {
      const updatedSetOfTickets = liveTickets.tickets.concat(tickets);

      setLiveTickets({
        ...liveTickets,
        tickets: updatedSetOfTickets,
        ticketIds: updatedSetOfTickets?.map(({ id }: { id: string }) => id),
        newTicketIds,
        hasTickets: updatedSetOfTickets?.length > 0,
      });
    },
    [liveTickets],
  );

  const deleteTickets = useCallback(
    ({ ticketId, ticketIds }: any) => {
      const updatedSetOfTickets = liveTickets.tickets.filter(({ id }) =>
        ticketIds ? !ticketIds.includes(id) : ticketId !== id,
      );

      setLiveTickets({
        ...liveTickets,
        tickets: updatedSetOfTickets,
        ticketIds: updatedSetOfTickets.map(({ id }) => id),
        hasTickets: updatedSetOfTickets.length > 0,
      });
    },
    [liveTickets],
  );

  /* NOTE: Handle ticket deletions */
  useEffect(() => {
    if (Object.keys(deletedTickets)?.length > 0) {
      setDeletedTickets(INITIAL_STATE.deletedTickets);
      deleteTickets(deletedTickets);
    }
  }, [deleteTickets, deletedTickets]);

  /* NOTE: Handle socket updates */
  useEffect(() => {
    if (newTickets.ticketIds.length > 0) {
      setNewTickets(INITIAL_STATE.newTickets);
      updateNewTickets({
        tickets: newTickets.tickets,
        newTicketIds: newTickets.ticketIds,
      });
    }
  }, [newTickets, user, updateNewTickets]);

  /* NOTE: Initiate */
  useEffect(() => {
    if (!liveTickets.isInitiated) {
      initiate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* NOTE: Socket connection */
  useEffect(() => {
    const socketId = 'socketLendTickets';
    const socket = createSocket(socketId);

    socket.on(
      'ticketDeletion',
      ({ ticketId, ticketIds }: { ticketId: string; ticketIds: string[] }) => {
        setDeletedTickets({ ticketId, ticketIds });
      },
    );

    socket.on('foundLendTickets', (data: any) => {
      setNewTickets({
        tickets: data,
        ticketIds: data.map(({ id }: any) => id),
      });
    });

    return () => {
      socket.off('ticketDeletion');
      socket.off('foundLendTickets');
      removeSocket(socketId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onEndReached = useCallback(() => {
    if (!isWeb) {
      return;
    }

    const numberOfVisualTickets = liveTickets.tickets.length;
    const minimumRequireMentOfVisualTickets = numberOfVisualTickets >= 10;
    const allTickets = [...liveTickets.allTickets].sort(
      (a: any, b: any) => b.createdAt - a.createdAt,
    );

    if (minimumRequireMentOfVisualTickets && numberOfVisualTickets < allTickets.length) {
      const diff = allTickets.length - numberOfVisualTickets;

      const loadMoreLiveTickets = liveTickets.tickets.concat(
        allTickets.slice(
          liveTickets.tickets.length,
          liveTickets.tickets.length + (diff >= 10 ? 10 : diff),
        ),
      );

      setLiveTickets({
        ...liveTickets,
        tickets: loadMoreLiveTickets,
        ticketIds: loadMoreLiveTickets.map(({ id }) => id),
      });
    }
  }, [liveTickets]);

  return {
    liveTickets,
    cards: liveTickets.isSearching
      ? liveTickets.searchTickets.slice().sort((a: any, b: any) => b.createdAt - a.createdAt)
      : liveTickets.tickets?.slice().sort((a: any, b: any) => b.createdAt - a.createdAt),
    handleTicketUpdates: initiate,
    onEndReached,
    onSwipeAndOnPressAction,
    searchReset,
    setLiveTickets,
  };
};
