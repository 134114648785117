import React, { useEffect, useCallback } from 'react';
import { useIsFocused, useNavigation, useRoute } from '@react-navigation/native';
import * as routeNames from '@lendticket/app/constants/routeNames';
import { HomePage } from '@lendticket/ui/components/templates';
import persisted from '@lendticket/app/helpers/storage/persisted';
import { useAppSelector } from '@lendticket/app/hooks/redux';
import { shallowEqual } from 'react-redux';
import { userSelector, userActions } from '@lendticket/app/reducers/slices/user';
import api from '@lendticket/app/reducers/slices/api';
import { useAppDispatch } from '@lendticket/app/helpers/redux/store';
import { useI18n } from '@lendticket/app/hooks/i18n';
import { loadingActions } from '@lendticket/app/reducers/slices/loading';

const getItems = ({ navigation, i18n, dispatch }: any) => [
  {
    iconName: 'userSettings',
    text: i18n.t('userSettings'),
    onPress: () => navigation.navigate(routeNames.UPDATE_USER),
  },
  {
    iconName: 'privacyPolicy',
    text: i18n.t('privacyPolicy'),
    onPress: () => navigation.navigate(routeNames.PRIVACY_POLICY),
  },
  {
    iconName: 'upcomming',
    text: i18n.t('upcomingFeatures'),
    onPress: () => navigation.navigate(routeNames.UPCOMING),
  },
  {
    iconName: 'support',
    text: i18n.t('support'),
    onPress: async () => navigation.navigate(routeNames.SUPPORT),
  },
  {
    iconName: 'logout',
    text: i18n.t('logout'),
    onPress: async () => {
      dispatch(userActions.resetUserInfo());
      await persisted.clear();
      navigation.reset({ index: 1, routes: [{ name: routeNames.LOGIN }] });
    },
  },
];

const Home: React.FC = () => {
  const i18n = useI18n();
  const user = useAppSelector(userSelector, shallowEqual);
  const dispatch = useAppDispatch();
  const isFocused = useIsFocused();
  const route = useRoute();
  const navigation = useNavigation();
  const items = getItems({ dispatch, i18n, navigation });

  const { getUserByAttribute } = api.endpoints;

  const initiate = useCallback(async () => {
    const { refetch } = dispatch(getUserByAttribute.initiate(user?.id));
    const { data } = await refetch();

    dispatch(
      loadingActions.setLoadingContent({ isLoading: false, text: undefined, isCancelable: false }),
    );

    if (data) {
      const latestUserDetails = data.user ?? {};
      const hasUserDataChanges = Object.values(user ?? {}).reduce((res, item, i) => {
        const { bad: currentBad, good: currentGood } = item as any;
        const { bad: derivedBad, good: derivedGood } = Object.values(user ?? {})[i] as any;
        const hasEvaluationBadChange = currentBad !== derivedBad;
        const hasEvaluationGoodChange = currentGood !== derivedGood;

        if (
          item !== Object.values(user ?? {})[i] ||
          hasEvaluationGoodChange ||
          hasEvaluationBadChange
        ) {
          res = true;
        }

        return res;
      }, false);

      if (hasUserDataChanges) {
        persisted.setItem('authToken', data.token);
        dispatch(userActions.addUser(latestUserDetails));
      }
    }
  }, [dispatch, getUserByAttribute, user]);

  useEffect(() => {
    if (isFocused && route?.name === routeNames.HOME) {
      initiate();
    }
  }, [initiate, isFocused, route]);

  return <HomePage options={items} profile={user} />;
};

export default Home;
