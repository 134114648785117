import React, { useContext } from 'react';
import { Platform } from 'react-native';
import { Route } from '@react-navigation/native';
import styled, { useTheme } from 'styled-components/native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { Text, Wrapper, Margin, MarginColumn } from '@lendticket/ui/components/atoms';
import Icon from '@lendticket/ui/components/molecules/Icon';
import { TranslationContext } from '@lendticket/app/contexts';
import { useI18n } from '@lendticket/app/hooks/i18n';

const isWeb = Platform.OS === 'web';
const ICON_DIMENSIONS = 32;
const ICON_DIMENSION_OFFSET = 5;
const ICON_DIMENSIONS_MOBILE = 28;
const TRANSLATE_ICON_OFFSET = 2;
export const WEB_MOBILE_HEIGHT = 75;
export const WEB_DESKTOP_TABLET_HEIGHT = 100;
export const NATIVE_MOBILE_HEIGHT = 50;

const TouchableOpacity = styled.TouchableOpacity``;
const LANGUAGES = {
  da: 'DA',
  se: 'SE',
  en: 'EN',
} as { [key: string]: string };

interface HeaderProps {
  isRouteLive: boolean;
  isRouteChatOrHeader: boolean;
  isRouteChat: boolean;
  isRouteLogin: boolean;
  isRouteFavorite: boolean;
  isChatDetailsVisible: boolean;
  isTicketOwner: boolean;
  isFavorite?: boolean;
  isModal: boolean;
  routeHasTranslationPossibility: boolean;
  title: { [key: string]: string };
  route: Route<string, object | undefined>;
  onPressLendedOut: () => void;
  goBack: () => void;
  onPressAddToFavorite: () => void;
  onPressChatDetailsVisibility: () => void;
}

const Header: React.FC<HeaderProps> = ({
  isRouteLive,
  isRouteChatOrHeader,
  isRouteChat,
  isRouteLogin,
  isChatDetailsVisible,
  isRouteOnboarding,
  isRouteFavorite,
  routeHasTranslationPossibility,
  isFavorite,
  isTicketOwner,
  isModal,
  titles,
  route,
  onPressAddToFavorite,
  onPressLendedOut,
  onPressChatDetailsVisibility,
  goBack,
}) => {
  const { openTranslationModal, currentSelectedLanguage } = useContext(TranslationContext);
  const i18n = useI18n();
  const isRouteHome = route?.name === 'home';
  const { settings, deviceSizes } = useTheme();
  const { top } = useSafeAreaInsets();
  const { isMobile } = deviceSizes;
  const { COLORS, SHADOWS, CONSTANTS } = settings;
  const { DESKTOP_TABLET_WIDTH } = CONSTANTS;
  const { TEXT_SHADOW } = SHADOWS;
  const derivedHeight = isMobile ? WEB_MOBILE_HEIGHT : WEB_DESKTOP_TABLET_HEIGHT;
  const derivedIconDimensions = isMobile ? ICON_DIMENSIONS_MOBILE : ICON_DIMENSIONS;
  const textColor = isRouteChatOrHeader && isMobile ? COLORS.TEXT_LIGHT_PURPLE : COLORS.WHITE;

  return (
    <Wrapper fd="column">
      <Wrapper
        h={isModal ? 0 : top}
        bc={isMobile && isRouteChatOrHeader ? COLORS.BACKGROUND_LIGHT : 'transparent'}
      />
      <Wrapper
        jc="center"
        ai="center"
        fd="row"
        w={isMobile ? undefined : DESKTOP_TABLET_WIDTH}
        h={derivedHeight}
        bc={isMobile && isRouteChatOrHeader ? COLORS.BACKGROUND_LIGHT : 'transparent'}
      >
        <MarginColumn />
        <Wrapper f={1} jc="flex-start">
          <Wrapper fd="row">
            {isModal && !isRouteLogin && !isRouteOnboarding && (
              <>
                <TouchableOpacity onPress={() => goBack()}>
                  <Icon
                    name="arrowLeft"
                    fill={textColor}
                    height={derivedIconDimensions - ICON_DIMENSION_OFFSET}
                    width={derivedIconDimensions - ICON_DIMENSION_OFFSET}
                  />
                </TouchableOpacity>
                <Margin marginRight="m" />
              </>
            )}
            {routeHasTranslationPossibility && (
              <TouchableOpacity onPress={openTranslationModal}>
                <Wrapper fd="row">
                  <Icon
                    name="globe"
                    fill={
                      (isRouteHome && !isWeb) || (isRouteHome && isWeb && isMobile)
                        ? COLORS.TEXT_LIGHT_PURPLE
                        : COLORS.WHITE
                    }
                    height={ICON_DIMENSIONS - TRANSLATE_ICON_OFFSET - ICON_DIMENSION_OFFSET}
                    width={ICON_DIMENSIONS - TRANSLATE_ICON_OFFSET - ICON_DIMENSION_OFFSET}
                  />
                  <Margin marginLeft="sm" />
                  <Text
                    fontType="body"
                    style={{
                      color:
                        (isRouteHome && !isWeb) || (isRouteHome && isWeb && isMobile)
                          ? COLORS.TEXT_LIGHT_PURPLE
                          : COLORS.WHITE,
                    }}
                  >
                    {LANGUAGES[currentSelectedLanguage]}
                  </Text>
                </Wrapper>
              </TouchableOpacity>
            )}
          </Wrapper>
        </Wrapper>
        <Text
          fontType="h3"
          style={{
            color: textColor,
            textAlign: 'center',
            textShadow: TEXT_SHADOW,
            wordBreak: 'break-word',
          }}
        >
          {titles[route.name]}
        </Text>
        <Wrapper f={1}>
          <Wrapper fd="row" jc="flex-end">
            {isRouteChat && (
              <>
                <TouchableOpacity onPress={onPressAddToFavorite}>
                  <Icon
                    name={isFavorite ? 'thumbsUp' : 'thumbsUpNoFill'}
                    fill={textColor}
                    height={derivedIconDimensions}
                    width={derivedIconDimensions}
                  />
                </TouchableOpacity>
                <Margin marginRight={isMobile ? 'sm' : 'm'} />
              </>
            )}
            {isRouteLive && !isMobile && !isRouteOnboarding && (
              <>
                <Text
                  fontType={isMobile ? 'body' : 'h4'}
                  style={{ color: COLORS.BACKGROUND_LIGHT }}
                >
                  {i18n.t('liveUpdate')}
                </Text>
                <Margin marginLeft="s" />
              </>
            )}
            {isRouteChat && (
              <>
                <TouchableOpacity onPress={onPressChatDetailsVisibility}>
                  <Icon
                    name={isChatDetailsVisible ? 'hide' : 'show'}
                    fill={isRouteLive ? COLORS.BACKGROUND_LIGHT : textColor}
                    height={derivedIconDimensions}
                    width={derivedIconDimensions}
                  />
                </TouchableOpacity>
                <Margin marginRight={isMobile ? 'sm' : 'm'} />
              </>
            )}
            {!isRouteFavorite && isRouteChat && isTicketOwner && (
              <TouchableOpacity onPress={onPressLendedOut}>
                <Icon
                  name="handshake"
                  fill={textColor}
                  height={derivedIconDimensions + 8}
                  width={derivedIconDimensions + 8}
                />
              </TouchableOpacity>
            )}
            {(isModal || isRouteLive) && !isRouteChat && !isRouteLogin && !isRouteOnboarding && (
              <TouchableOpacity onPress={goBack}>
                <Icon
                  name={isRouteLive ? 'live' : 'cross'}
                  fill={isRouteLive ? COLORS.BACKGROUND_LIGHT : textColor}
                  height={isRouteLive ? ICON_DIMENSIONS : derivedIconDimensions}
                  width={isRouteLive ? ICON_DIMENSIONS : derivedIconDimensions}
                />
              </TouchableOpacity>
            )}
          </Wrapper>
        </Wrapper>
        <MarginColumn />
      </Wrapper>
    </Wrapper>
  );
};

export default Header;
