import { swiperButtonHandler } from '@lendticket/ui/components/organisms/SwiperButton/handler';
import { CreatePage } from '@lendticket/ui/components/templates';
import { useNavigation } from '@react-navigation/native';
import * as routeNames from '@lendticket/app/constants/routeNames';
import { alertMessage } from '@lendticket/app/helpers/alertMessage';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useCheckForUserCreations } from './hooks/useCheckForUserCreations';
import { useOnDestinationSearch } from './hooks/useOnDestinationSearch';
import { useOnFormSubmit } from './hooks/useOnFormSubmit';
import { useI18n } from '@lendticket/app/hooks/i18n';
import { ModalContext } from 'contexts';

const DEFAULT_STATE_SELECTED_PERIOD = {
  markedDates: {},
  isStartDatePicked: false,
  isEndDatePicked: false,
  startDate: '',
};

const Create: React.FC = () => {
  const i18n = useI18n();
  const { modalState, setModalState } = useContext(ModalContext);
  const [isAllOfSkaeneSelected, setIsAllOfSkaeneSelected] = useState({
    ticket: false,
    profile: false,
  });
  const [datePickerDates, setDatePickerDates] = useState({
    ticket: DEFAULT_STATE_SELECTED_PERIOD,
    profile: DEFAULT_STATE_SELECTED_PERIOD,
  });
  const [multipleDataSets, setMultipleDataSets] = useState<any>([]);
  const [toggleTicketTypesAndPayment, setToggleTicketTypesAndPayment] = useState<any>({
    ticket: {},
    profile: {},
  });
  const [selectedPeriods, setSelectedPeriods] = useState<any>({
    ticket: [],
    profile: [],
  });
  const [hasCreations, setHasCreations] = useState<undefined | boolean>();
  const { control, handleSubmit, getValues } = useForm({ mode: 'onBlur' });
  const navigation = useNavigation();
  const onFormSubmit = useOnFormSubmit();
  const onDestinationSearch = useOnDestinationSearch();
  const checkForUserCreations = useCheckForUserCreations();

  const getSelectedPeriods = useCallback(
    (isLendingOutSelected?: boolean) => {
      const { startDate, markedDates } = isLendingOutSelected
        ? datePickerDates.ticket
        : datePickerDates.profile;

      const keys = Object.keys(markedDates);
      const isStartDatePicked = isLendingOutSelected
        ? datePickerDates.ticket.startDate !== ''
        : datePickerDates.profile.startDate !== '';

      return [
        {
          periodStart: isStartDatePicked ? startDate : keys[0],
          periodEnd: isStartDatePicked ? startDate : keys[keys.length - 1],
        },
      ] as any;
    },
    [datePickerDates],
  );

  const updateSelectedPeriods = useCallback(
    (isLendingOutSelected?: boolean) => {
      const updateParams = getSelectedPeriods(isLendingOutSelected);
      const currentFormValues = getValues();
      const formValues = Object.keys(getValues() ?? {}).reduce((obj, item, i) => {
        if (isLendingOutSelected && item.includes('_ticket')) {
          obj = {
            ...obj,
            [item.replace('_ticket', '')]: Object.values(currentFormValues)[i],
          };
        } else if (!isLendingOutSelected && item.includes('_profile')) {
          obj = {
            ...obj,
            [item.replace('_profile', '')]: Object.values(currentFormValues)[i],
          };
        }

        return obj;
      }, {} as any);

      if (!updateParams[0]?.periodEnd || !updateParams[0]?.periodStart) {
        alertMessage(i18n.t('alertPleaseSelectOneOrMultipleDates'));
        return;
      }

      setMultipleDataSets([...multipleDataSets, formValues]);

      setSelectedPeriods({
        ticket: [...selectedPeriods?.ticket, ...(isLendingOutSelected ? updateParams : [])],
        profile: [...selectedPeriods?.profile, ...(isLendingOutSelected ? [] : updateParams)],
      });

      setDatePickerDates({
        ticket: DEFAULT_STATE_SELECTED_PERIOD,
        profile: DEFAULT_STATE_SELECTED_PERIOD,
      });
    },
    [
      getSelectedPeriods,
      getValues,
      i18n,
      multipleDataSets,
      selectedPeriods?.ticket,
      selectedPeriods?.profile,
    ],
  );

  const onSubmit = useCallback(
    (data: any) => {
      const isLendingOutSelected = swiperButtonHandler.getIsLendingOutTicketSelected();

      setModalState({
        ...modalState,
        onConfirmButtonText: i18n.t('promote'),
        onCloseButtonText: i18n.t('createAnotherPeriod'),
        title: i18n.t('areYouReadyToPublish'),
        message: i18n.t('youCanCreateMultiplePeriods'),
        visible: true,
        onClose: () => updateSelectedPeriods(isLendingOutSelected),
        onConfirm: () => {
          const hasSelectedMultiplePeriods = isLendingOutSelected
            ? selectedPeriods.ticket.length >= 1
            : selectedPeriods.profile.length >= 1;

          const derivedSelectedPeriods = hasSelectedMultiplePeriods
            ? isLendingOutSelected
              ? selectedPeriods.ticket
              : selectedPeriods.profile
            : getSelectedPeriods(isLendingOutSelected);

          const finalSelectionOfPeriods = [
            ...derivedSelectedPeriods,
            ...(hasSelectedMultiplePeriods ? getSelectedPeriods(isLendingOutSelected) : []),
          ].filter(({ periodStart }) => typeof periodStart !== 'undefined');

          setModalState({ ...modalState, isVisible: false });
          onFormSubmit({
            data,
            isLendingOutSelected,
            multipleDataSets,
            isAllOfSkaeneSelected: isLendingOutSelected
              ? isAllOfSkaeneSelected.ticket
              : isAllOfSkaeneSelected.profile,
            ticketTypesAndPayment: toggleTicketTypesAndPayment,
            selectedPeriods: finalSelectionOfPeriods,
            setSelectedPeriods,
            setHasCreations,
            resetFormStates: () => {
              setMultipleDataSets([]);
              setDatePickerDates({
                ticket: DEFAULT_STATE_SELECTED_PERIOD,
                profile: DEFAULT_STATE_SELECTED_PERIOD,
              });
              setIsAllOfSkaeneSelected({ ticket: false, profile: false });
              setSelectedPeriods({ ticket: [], profile: [] });
            },
          });
        },
      });
    },
    [
      selectedPeriods,
      setModalState,
      modalState,
      i18n,
      multipleDataSets,
      isAllOfSkaeneSelected,
      toggleTicketTypesAndPayment,
      updateSelectedPeriods,
      getSelectedPeriods,
      onFormSubmit,
    ],
  );

  const onSwipeAndOnPressAction = useCallback(() => {
    swiperButtonHandler.updateIsLendingOutTicket();
  }, []);

  useEffect(() => {
    if (typeof hasCreations === 'undefined') {
      checkForUserCreations((res: boolean) => {
        setHasCreations(res);
      });
    }
  }, [checkForUserCreations, hasCreations]);

  return (
    <>
      <CreatePage
        control={control}
        selectedPeriods={selectedPeriods}
        hasCreations={hasCreations}
        datePickerDates={datePickerDates}
        toggleTicketTypesAndPayment={toggleTicketTypesAndPayment}
        isAllOfSkaeneSelected={isAllOfSkaeneSelected}
        setIsAllOfSkaeneSelected={setIsAllOfSkaeneSelected}
        setToggleTicketTypesAndPayment={setToggleTicketTypesAndPayment}
        setDatePickerDates={setDatePickerDates}
        updateSelectedPeriods={updateSelectedPeriods}
        onDestinationSearch={onDestinationSearch}
        onPressPublish={handleSubmit(onSubmit)}
        swiperCallback={onSwipeAndOnPressAction}
        onPressEdit={() => navigation.navigate(routeNames.EDIT)}
      />
    </>
  );
};

export default Create;
