import { useCallback } from 'react';
import { jwtDecode } from 'jwt-decode';
import { useNavigation } from '@react-navigation/native';
import { useAppDispatch } from '@lendticket/app/hooks/redux';
import * as routeNames from '@lendticket/app/constants/routeNames';
import persisted from '@lendticket/app/helpers/storage/persisted';
import { userActions } from '@lendticket/app/reducers/slices/user';
import { useRegisterNotification } from './useRegisterNotification';
import { isWeb } from '@lendticket/app/helpers/platform';

export const useUpdateUserSetAuthTokenAndRedirect = () => {
  const dispatch = useAppDispatch();
  const navigation = useNavigation();
  const registerNotification = useRegisterNotification();

  return useCallback(
    async (data?: any, withNavigation = true) => {
      if (data) {
        const authToken = data?.token;

        persisted.setItem('authToken', data.token);
        const decodedToken = jwtDecode(data.token);
        dispatch(userActions.addUser(decodedToken));

        if (!isWeb) {
          registerNotification({ authToken, withNavigation });
        } else if (withNavigation) {
          (navigation as any).navigate(routeNames.DRAWER_AND_TABBAR, {
            screen: routeNames.TABS,
            params: { screen: routeNames.HOME },
          });
        }
      } else if (withNavigation) {
        (navigation as any).navigate(routeNames.DRAWER_AND_TABBAR, {
          screen: routeNames.TABS,
          params: { screen: routeNames.HOME },
        });
      }
    },
    [dispatch, navigation, registerNotification],
  );
};
