import React, { useState, useCallback, useContext } from 'react';
import { Margin, Wrapper } from '@lendticket/ui/components/atoms';
import { Card, NoContent } from '@lendticket/ui/components/molecules';
import { Appearance } from '@lendticket/ui/components/organisms';
import { PageDimensionsContext } from '@lendticket/ui/containers/PageDimensionsProvider';
import { isWeb } from '@lendticket/app/helpers/platform';
import { useAppSelector } from '@lendticket/app/hooks/redux';
import { FlatList } from 'react-native';
import { useSharedValue, useAnimatedReaction, runOnJS } from 'react-native-reanimated';
import { shallowEqual } from 'react-redux';
import { userSelector } from '@lendticket/app/reducers/slices/user';
import styled, { useTheme } from 'styled-components/native';
import { INITIAL_STATE as INITIAL_STATE_PROFILES } from './hooks/useGenerateProfiles';
import { INITIAL_STATE as INITIAL_STATE_TICKETS } from './hooks/useGenerateTickets';
import { useI18n } from '@lendticket/app/hooks/i18n';
import { alertMessage } from '@lendticket/app/helpers/alertMessage';
import { ModalContext } from 'contexts';

const ANDROID_FADING_EDGE_LENGTH = 16;

interface OverviewProps {
  cards:
    | typeof INITIAL_STATE_TICKETS.liveTickets.tickets
    | typeof INITIAL_STATE_PROFILES.liveProfiles.profiles;
  newCardIds: number[];
  hasCards: boolean;
  isSearching: boolean;
  prematureStartLoader: () => void;
  updateData: () => void;
  onEndReached: () => void;
  onSwipeAndOnPressAction: (props: any) => void;
}

const Seperator = styled.View`
  margin-top: ${({ theme }) => theme.settings.SPACINGS.md}px;
`;

const Overview: React.FC<OverviewProps> = ({
  cards,
  hasCards,
  isSearching,
  newCardIds,
  updateData,
  onEndReached,
  onSwipeAndOnPressAction,
  prematureStartLoader,
}) => {
  const i18n = useI18n();
  const { setModalState } = useContext(ModalContext);
  const { footerHeight, headerHeight, extraHeight } = useContext(PageDimensionsContext);
  const isReadyToCalculateHeightForWeb = useSharedValue(false);
  const [derivedContentHeight, setDerivedContentHeight] = useState(0);
  const { windowDimensions, settings } = useTheme();
  const { SPACINGS } = settings;
  const { windowHeight } = windowDimensions;
  const user = useAppSelector(userSelector, shallowEqual);

  useAnimatedReaction(
    () => ({ extraHeight, footerHeight, headerHeight }),
    () => {
      if (isWeb && extraHeight.value && footerHeight.value && headerHeight.value) {
        runOnJS(setDerivedContentHeight)(
          windowHeight - extraHeight.value - footerHeight.value - headerHeight.value - SPACINGS.md,
        );
      }
    },
    [isReadyToCalculateHeightForWeb],
  );

  const derivedOnSwipeAndOnPressAction = useCallback(
    ({ id, createdByUserId }: any) => {
      if (user) {
        if (user?.id !== createdByUserId) {
          prematureStartLoader();
          onSwipeAndOnPressAction({ id, createdByUserId });
        } else {
          alertMessage(i18n.t('youCannotStartAChatWithYourself'));
        }
      } else {
        setModalState({
          visible: true,
          title: i18n.t('loginOrCreateUser'),
          onConfirmButtonText: i18n.t('login'),
          message: i18n.t('letOthersKnowWhoYouAre'),
          type: 'unauthorized',
        });
      }
    },
    [user, prematureStartLoader, onSwipeAndOnPressAction, i18n, setModalState],
  );

  const height = isWeb ? { height: derivedContentHeight } : {};

  return (
    <>
      {(!hasCards || (isSearching && cards?.length === 0)) && (
        <Wrapper ai="flex-start">
          <Margin marginTop="md" />
          <NoContent text={i18n.t('noCreations')} />
        </Wrapper>
      )}
      {hasCards && (
        <FlatList
          onEndReached={onEndReached}
          onEndReachedThreshold={2}
          fadingEdgeLength={ANDROID_FADING_EDGE_LENGTH}
          style={{
            width: '100%',
            ...height,
          }}
          keyboardDismissMode="on-drag"
          refreshing={false}
          onRefresh={updateData}
          data={cards}
          ItemSeparatorComponent={Seperator}
          renderItem={({ item, index }) =>
            newCardIds.includes(item.id) ? (
              <Appearance delay={(index + 1) * 100}>
                <Card
                  hiddenCardText={i18n.t('startConversation')}
                  onPressAction={() =>
                    derivedOnSwipeAndOnPressAction({
                      id: item.id,
                      createdByUserId: item.createdByUserId,
                    })
                  }
                  onSwipeAction={() =>
                    derivedOnSwipeAndOnPressAction({
                      id: item.id,
                      createdByUserId: item.createdByUserId,
                    })
                  }
                  {...item}
                />
              </Appearance>
            ) : (
              <Card
                hiddenCardText={i18n.t('startConversation')}
                onPressAction={() =>
                  derivedOnSwipeAndOnPressAction({
                    id: item.id,
                    createdByUserId: item.createdByUserId,
                  })
                }
                onSwipeAction={() =>
                  derivedOnSwipeAndOnPressAction({
                    id: item.id,
                    createdByUserId: item.createdByUserId,
                  })
                }
                {...item}
              />
            )
          }
          keyExtractor={({ createdAt }, i) => `${createdAt}-${i}`}
        />
      )}
    </>
  );
};

export default Overview;
