import React, { useContext } from 'react';
import { Platform } from 'react-native';
import styled, { useTheme } from 'styled-components/native';
import { Margin, Wrapper, Text, Button } from '@lendticket/ui/components/atoms';
import Icon from '@lendticket/ui/components/molecules/Icon';
import { useI18n } from '@lendticket/app/hooks/i18n';
import { ModalContext } from '@lendticket/app/contexts';
import { navigationRef } from '@lendticket/app/helpers/navigation/config';
import * as routeNames from '@lendticket/app/constants/routeNames';

const isIOS = Platform.OS === 'ios';
const ICON_DIMENSIONS = 40;
const BUTTON_WRAPPER_HEIGHT = 50;
const HEIGHT = 20;
const PADDING = 8;

interface LoginWithFBGoogleOrAppleProps {
  actions: {
    [key: string]: {
      title: string;
      action: (onSubmit?: () => void) => void;
    };
  };
  type: 'create' | 'login';
}

const Line = styled.View`
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 2px;
  background-color: ${({ theme }) => theme.settings.COLORS.OPACITY_COLOR_TEXT};
`;

const LoginWithFBGoogleOrApple: React.FC<LoginWithFBGoogleOrAppleProps> = ({ actions, type }) => {
  const { setModalState } = useContext(ModalContext);
  const i18n = useI18n();
  const { COLORS } = useTheme().settings;

  return (
    <>
      <Wrapper h={HEIGHT} fd="row">
        <Line />
        <Text
          fontType="body"
          style={{
            color: COLORS.WHITE,
            paddingLeft: PADDING,
            paddingRight: PADDING,
          }}
        >
          {i18n.t('or')}
        </Text>
        <Line />
      </Wrapper>
      <Margin marginTop="md">
        <Wrapper h={BUTTON_WRAPPER_HEIGHT} fd="row" jc="center" ai="center">
          <Button
            onPress={() =>
              setModalState({
                visible: true,
                title: i18n.t('facebookLoginDisabledTitle'),
                onConfirmButtonText: i18n.t('forgotPassword'),
                message: i18n.t('facebookLoginDisabledDescription'),
                type: 'facebookDisabled',
                onClose: () => navigationRef.current?.navigate(routeNames.LOGIN),
                onConfirm: () => navigationRef.current?.navigate(routeNames.FORGOT_PASSWORD),
              })
            }
          >
            <Icon name="facebook" height={ICON_DIMENSIONS} width={ICON_DIMENSIONS} />
          </Button>
          <Margin marginLeft="md">
            <Button onPress={() => actions.google.action(type)}>
              <Icon name="google" height={ICON_DIMENSIONS} width={ICON_DIMENSIONS} />
            </Button>
          </Margin>
          {isIOS && typeof actions.apple.action === 'function' && (
            <Margin marginLeft="s">
              <Button onPress={() => actions.apple.action(type)}>
                <Icon name="apple" height={ICON_DIMENSIONS + 8} width={ICON_DIMENSIONS + 8} />
              </Button>
            </Margin>
          )}
        </Wrapper>
      </Margin>
    </>
  );
};

export default LoginWithFBGoogleOrApple;
