import React from 'react';
import { TouchableOpacity } from 'react-native';
import { useTheme } from 'styled-components/native';
import { Margin, Wrapper, Text } from '@lendticket/ui/components/atoms';
import { useI18n } from '@lendticket/app/hooks/i18n';

const PADDING = 8;
const BORDER_WIDTH = 2;
const BORDER_RADIUS = 5;

interface ShowCalendarButtonProps {
  onPress: () => void;
}

const ShowCalendarButton: React.FC<ShowCalendarButtonProps> = ({ onPress }) => {
  const i18n = useI18n();
  const { deviceSizes, settings } = useTheme();
  const { isMobile } = deviceSizes;
  const { COLORS } = settings;

  return (
    <>
      <Margin marginTop="m" />
      <TouchableOpacity onPress={onPress}>
        <Wrapper
          style={{
            borderWidth: BORDER_WIDTH,
            borderColor: COLORS.BACKGROUND_EXTRA_LIGHT_ORANGE,
            borderRadius: BORDER_RADIUS,
          }}
        >
          <Text
            fontType={isMobile ? 'body' : 'h4'}
            style={{ color: COLORS.WHITE, padding: PADDING, fontWeight: 'bold' }}
          >
            {i18n.t('calendarChoosePeriods')}
          </Text>
        </Wrapper>
      </TouchableOpacity>
    </>
  );
};

export default ShowCalendarButton;
