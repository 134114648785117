import React from 'react';
import { Platform } from 'react-native';
import { useTheme } from 'styled-components/native';
import { Wrapper, Text, Button, Margin } from '@lendticket/ui/components/atoms';
import Icon from '@lendticket/ui/components/molecules/Icon';
import { useI18n } from '@lendticket/app/hooks/i18n';

const isWeb = Platform.OS === 'web';
const BORDER_TOP_RADIUS = 7;
const ICON_DIMENSIONS = 28;
const HEIGHT = 50;

interface FooterCreatePageProps {
  hasCreations?: boolean;
  onPressPublish: () => void;
  onPressEdit: () => void;
}

const FooterCreatePage: React.FC<FooterCreatePageProps> = ({
  hasCreations,
  onPressPublish,
  onPressEdit,
}) => {
  const i18n = useI18n();
  const { settings } = useTheme();
  const { COLORS } = settings;

  return (
    <Wrapper
      bc={COLORS.BACKGROUND_LIGHT_ORANGE}
      fd="row"
      style={{
        borderTopRightRadius: BORDER_TOP_RADIUS,
        borderTopLeftRadius: BORDER_TOP_RADIUS,
      }}
    >
      <Wrapper f={1} jc="center" ai="center" h={HEIGHT}>
        <Button fd="row" onPress={onPressEdit}>
          <Icon
            name="edit"
            fill={hasCreations ? COLORS.WHITE : COLORS.BACKGROUND_EXTRA_LIGHT_ORANGE}
            height={ICON_DIMENSIONS}
            width={ICON_DIMENSIONS}
          />
          <Margin marginLeft="sm" />
          <Text
            fontType={isWeb ? 'h4' : 'h5'}
            style={{ color: hasCreations ? COLORS.WHITE : COLORS.BACKGROUND_EXTRA_LIGHT_ORANGE }}
          >
            {i18n.t('administer')}
          </Text>
        </Button>
      </Wrapper>
      <Wrapper f={1} jc="center" ai="center" h={HEIGHT}>
        <Button fd="row" onPress={onPressPublish}>
          <Icon
            name="publish"
            fill={COLORS.WHITE}
            height={ICON_DIMENSIONS}
            width={ICON_DIMENSIONS}
          />
          <Margin marginLeft="sm" />
          <Text fontType={isWeb ? 'h4' : 'h5'} style={{ color: COLORS.WHITE }}>
            {i18n.t('promote')}
          </Text>
        </Button>
      </Wrapper>
    </Wrapper>
  );
};

export default FooterCreatePage;
