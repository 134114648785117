import React, { useMemo, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { jwtDecode } from 'jwt-decode';
import { useNavigation, useIsFocused } from '@react-navigation/native';
import { FavoritesPage } from '@lendticket/ui/components/templates';
import { useAppSelector } from '@lendticket/app/hooks/redux';
import { userSelector, userActions } from '@lendticket/app/reducers/slices/user';
import { shallowEqual } from 'react-redux';
import * as routeNames from '@lendticket/app/constants/routeNames';
import api from '@lendticket/app/reducers/slices/api';
import { alertMessage } from '@lendticket/app/helpers/alertMessage';
import persisted from '@lendticket/app/helpers/storage/persisted';
import { useI18n } from '@lendticket/app/hooks/i18n';
import { useAppDispatch } from '@lendticket/app/helpers/redux/store';
import { loadingActions } from '@lendticket/app/reducers/slices/loading';

const Favorites: React.FC = () => {
  const [hasFavorites, setHasFavorites] = useState(true);
  const [refreshData, setRefreshData] = useState(false);
  const [favorites, setFavorites] = useState([]);
  const [isInitiated, setIsInitiated] = useState(false);
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const user = useAppSelector(userSelector, shallowEqual);
  const i18n = useI18n();
  const isFocused = useIsFocused();
  const navigation = useNavigation();
  const dispatch = useAppDispatch();
  const { control } = useForm({ mode: 'onBlur' });
  const derivedFavorites = useMemo(
    () =>
      searchResults?.length > 0
        ? favorites.filter(({ name }) =>
            searchResults.map(({ name: searchResultNames }) => searchResultNames).includes(name),
          )
        : favorites,
    [favorites, searchResults],
  );

  const { getChatByFavoriteIdAndUserId } = api.endpoints;
  const [updateUserByUserId] = api.useUpdateUserByUserIdMutation();
  const [createFavoritesByUserIds] = api.useCreateFavoritesByUserIdsMutation();

  const onPressAction = useCallback(
    async ({ id }: any) => {
      dispatch(
        loadingActions.setLoadingContent({
          isLoading: true,
          hasBackgroundColor: true,
          indicator: 'circle',
        }),
      );

      const { data } = await dispatch(
        getChatByFavoriteIdAndUserId.initiate({ favoriteId: id, userId: user?.id }),
      );
      const { chat } = data ?? {};

      dispatch(
        loadingActions.setLoadingContent({
          isLoading: false,
          hasBackgroundColor: false,
          indicator: 'line',
        }),
      );

      if (!chat) {
        navigation.navigate(routeNames.CHAT, {
          favoriteId: id,
          userId: user?.id,
          isFavorite: true,
        });
      } else {
        navigation.navigate(routeNames.CHAT, {
          chatId: chat[0].id,
          userId: user?.id,
          isFavorite: true,
        });
      }
    },
    [dispatch, getChatByFavoriteIdAndUserId, navigation, user],
  );

  const onSearchChange = useCallback(
    (value: string) => {
      setSearchResults(
        favorites.filter(({ name }) => name.toLowerCase().includes(value.toLowerCase())),
      );
    },
    [favorites],
  );

  const initialUpdateFavories = useCallback(async () => {
    createFavoritesByUserIds(user?.favorites ?? [])
      .then((res: any) => {
        setFavorites(res.data.favorites);
        setHasFavorites(res.data.favorites.length > 0);
        setIsInitiated(true);
      })
      .catch((error: any) => {
        alertMessage(error);
      });
  }, [createFavoritesByUserIds, user]);

  const onSwipeDeleteFavorite = useCallback(
    async (id: string) => {
      const updatedSetOfFavorites =
        favorites?.filter(({ id: favoriteId }: { id: string }) => id !== favoriteId) ?? [];

      const { data }: any = await updateUserByUserId({
        userId: user?.id,
        data: {
          favorites: updatedSetOfFavorites.map(({ id: favoriteId }: { id: string }) => favoriteId),
        },
      });

      if (data?.token) {
        setFavorites(updatedSetOfFavorites);
        setHasFavorites(updatedSetOfFavorites.length > 0);
        persisted.setItem('authToken', data.token);
        const decodedToken = jwtDecode(data.token);
        dispatch(userActions.addUser(decodedToken));
      }
    },
    [dispatch, updateUserByUserId, favorites, user?.id],
  );

  useEffect(() => {
    if (isFocused && !isInitiated) {
      initialUpdateFavories();
    } else if (isFocused && isInitiated && !refreshData && hasFavorites) {
      setHasFavorites(false);
    } else if (isFocused && refreshData) {
      setRefreshData(false);
      initialUpdateFavories();
    } else if (!isFocused) {
      setRefreshData(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFocused]);

  return (
    <FavoritesPage
      control={control}
      newFavoriteIds={derivedFavorites.map(({ id }) => id).slice(0, 5)}
      favorites={derivedFavorites}
      hasFavorites={hasFavorites}
      introText={i18n.t('addOrRemoveFavoriteSwipeToDelete')}
      onSearchChange={onSearchChange}
      onPressAction={onPressAction}
      onSwipeDeleteFavorite={onSwipeDeleteFavorite}
      hiddenCardText={i18n.t('delete')}
      isInitiated={isInitiated}
    />
  );
};

export default Favorites;
