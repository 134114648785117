import { useAppDispatch, useAppSelector } from '@lendticket/app/hooks/redux';
import { useCallback } from 'react';
import { jwtDecode } from 'jwt-decode';
import { shallowEqual } from 'react-redux';
import api from '@lendticket/app/reducers/slices/api';
import persisted from '@lendticket/app/helpers/storage/persisted';
import { userSelector, userActions } from '@lendticket/app/reducers/slices/user';
import { favoriteSelector } from '@lendticket/app/reducers/slices/api/chats';
import { alertMessage } from '@lendticket/app/helpers/alertMessage';
import { useI18n } from '@lendticket/app/hooks/i18n';

export const useHandleAddOrRemoveFavorite = () => {
  const user = useAppSelector(userSelector, shallowEqual);
  const i18n = useI18n();
  const dispatch = useAppDispatch();
  const { isFavorite, favoriteUserId } = useAppSelector(favoriteSelector, shallowEqual) ?? {};

  const [updateUserByUserId] = api.useUpdateUserByUserIdMutation();

  return useCallback(async () => {
    if (!favoriteUserId) {
      alertMessage(i18n.t('addOrRemoveFavoriteError'));

      return;
    }

    const updatedSetOfAddedFavorites = user?.favorites
      ? [...user.favorites, favoriteUserId]
      : [favoriteUserId];

    const updatedSetOfRemovedFavorites =
      user?.favorites?.filter((id: string) => id !== favoriteUserId) ?? [];

    const derivedUpdatedSetOfFavorites = isFavorite
      ? updatedSetOfRemovedFavorites
      : updatedSetOfAddedFavorites;

    const { data }: any = await updateUserByUserId({
      userId: user?.id,
      data: { favorites: derivedUpdatedSetOfFavorites },
    });

    if (data?.token) {
      persisted.setItem('authToken', data.token);
      const decodedToken = jwtDecode(data.token);
      dispatch(userActions.addUser(decodedToken));
    }
  }, [favoriteUserId, user, isFavorite, updateUserByUserId, i18n, dispatch]);
};
