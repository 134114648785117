import React, { useRef } from 'react';
import { View } from 'react-native';
import { useTheme } from 'styled-components/native';
import { SafeAreaProvider, SafeAreaView } from 'react-native-safe-area-context';
import { Wrapper } from '@lendticket/ui/components/atoms';
import {
  FooterForgotPasswordPage,
  FullWrapperHeight,
  Gradient,
} from '@lendticket/ui/components/molecules';
import DefaultPage from '@lendticket/ui/components/templates/DefaultPage';
import { KeyboardAvoid } from '@lendticket/ui/components/organisms';
import type { SvgImageProps } from '@lendticket/ui/components/molecules/SvgImage';

interface ForgotPasswordPageProps {
  teaserTitle: string;
  teaserText: string;
  footerDescription?: string;
  svgName: SvgImageProps['name'];
  footerText: string;
  footerOnPress: () => void;
  inputFieldsContainerHeight?: number;
  inputFieldsComponent: React.ReactNode;
}

const ForgotPasswordPage: React.FC<ForgotPasswordPageProps> = ({
  teaserTitle,
  teaserText,
  svgName,
  footerText,
  footerDescription,
  footerOnPress,
  inputFieldsContainerHeight,
  inputFieldsComponent,
}) => {
  const parentRef = useRef(null);
  const { deviceSizes, settings } = useTheme();
  const { SPACINGS, CONSTANTS } = settings;
  const { isMobile } = deviceSizes;
  const { DESKTOP_TABLET_WIDTH } = CONSTANTS;

  return (
    <SafeAreaProvider>
      <SafeAreaView>
        <Gradient />
        <View ref={parentRef}>
          <FullWrapperHeight
            isModal
            hasBottomTabBar={false}
            footer={
              <FooterForgotPasswordPage
                footerText={footerText}
                footerDescription={footerDescription}
                footerOnPress={footerOnPress}
              />
            }
          >
            <Wrapper
              f={1}
              ai='flex-start'
              mb={SPACINGS.md}
              w={isMobile ? undefined : DESKTOP_TABLET_WIDTH}
            >
              <Wrapper f={1} fd='column' jc='center' ai='flex-start'>
                <DefaultPage
                  teaserTitle={teaserTitle}
                  teaserText={teaserText}
                  svgName={svgName}
                  inputFieldsContainerHeight={inputFieldsContainerHeight}
                  inputFieldsComponent={
                    <KeyboardAvoid isRelativeToParent isModal parentRef={parentRef}>
                      {inputFieldsComponent}
                    </KeyboardAvoid>
                  }
                />
              </Wrapper>
            </Wrapper>
          </FullWrapperHeight>
        </View>
      </SafeAreaView>
    </SafeAreaProvider>
  );
};

export default ForgotPasswordPage;
