import React, { Suspense, useState, lazy } from 'react';
import { useTheme } from 'styled-components/native';
import { Margin, Wrapper, Text } from '@lendticket/ui/components/atoms';
import Animated, { useAnimatedStyle, interpolate } from 'react-native-reanimated';
import Destinations from './Destinations';
import Practical from './Practical';
import TicketTypesAndPayment from './TicketTypesAndPayment';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { LabelAndDescription } from '@lendticket/ui/components/molecules';
import ShowCalendarButton from './ShowCalendarButton';
import { useI18n } from '@lendticket/app/hooks/i18n';

const Calendar = lazy(() => import('./Calendar'));

const EXTRA_SCROLL_HEIGHT = 120;
const MARGIN_BOTTOM = 80;

const CreatePageForm: React.FC<any> = ({
  control,
  selectedPeriods,
  animatedValues,
  datePickerDates,
  toggleTicketTypesAndPayment,
  setToggleTicketTypesAndPayment,
  onDestinationSearch,
  updateSelectedPeriods,
  isAllOfSkaeneSelected,
  setIsAllOfSkaeneSelected,
  setDatePickerDates,
}) => {
  const i18n = useI18n();
  const [showCalendars, setShowCalendars] = useState({
    showLendTicketCalendar: false,
    showLendProfileCalendar: false,
  });
  const { windowDimensions, deviceSizes, settings } = useTheme();
  const { windowWidth } = windowDimensions;
  const { CONSTANTS, SPACINGS } = settings;
  const { isMobile, isDesktop, isTablet } = deviceSizes;
  const { DESKTOP_TABLET_WIDTH } = CONSTANTS;
  const derivedWidth = isMobile
    ? windowWidth - SPACINGS.md * 2
    : isDesktop
    ? DESKTOP_TABLET_WIDTH - SPACINGS.xl * 2
    : DESKTOP_TABLET_WIDTH - SPACINGS.xl * 2;
  const { translationX, swiperWidth } = animatedValues;
  const iOSLeft = isTablet ? { paddingLeft: SPACINGS.xl } : { marginLeft: SPACINGS.xl };

  const animatedStyleCreateTicket = useAnimatedStyle(
    () => ({
      width: derivedWidth,
      opacity: interpolate(translationX.value, [0, swiperWidth.value / 2], [1, 0]),
      transform: [
        {
          translateX: interpolate(
            translationX.value,
            [0, swiperWidth.value / 1.5],
            [0, -derivedWidth],
          ),
        },
      ],
    }),
    [derivedWidth, swiperWidth, translationX],
  );

  const animatedStyleCreateTicketLender = useAnimatedStyle(
    () => ({
      width: derivedWidth,
      opacity: interpolate(translationX.value, [0, swiperWidth.value / 2], [0, 1]),
      transform: [
        {
          translateX: interpolate(
            translationX.value,
            [0, swiperWidth.value],
            [derivedWidth, -derivedWidth],
          ),
        },
      ],
    }),
    [derivedWidth, swiperWidth, translationX],
  );

  return (
    <Wrapper p="relative" fd="row" ai="center" mb={MARGIN_BOTTOM} style={iOSLeft}>
      <Animated.View style={animatedStyleCreateTicket}>
        <KeyboardAwareScrollView
          keyboardShouldPersistTaps="handled"
          keyboardDismissMode="on-drag"
          enableResetScrollToCoords={false}
          extraScrollHeight={EXTRA_SCROLL_HEIGHT}
          style={{ height: '100%', width: '100%' }}
        >
          <Margin marginTop="md" marginBottom="lg">
            <Destinations
              isAllOfSkaeneSelected={isAllOfSkaeneSelected}
              isChecked={isAllOfSkaeneSelected.ticket}
              setIsAllOfSkaeneSelected={setIsAllOfSkaeneSelected}
              isLendingOutSelected
              control={control}
              onDestinationSearch={onDestinationSearch}
            />
          </Margin>
          <Margin marginTop="md" marginBottom="lg">
            <Practical isLendingOutSelected hasProvider control={control} />
          </Margin>
          <Margin marginTop="md" marginBottom="lg">
            <TicketTypesAndPayment
              isLendingOutSelected
              toggleTicketTypesAndPayment={toggleTicketTypesAndPayment}
              setToggleTicketTypesAndPayment={setToggleTicketTypesAndPayment}
            />
          </Margin>
          <LabelAndDescription
            title={i18n.t('createCalendarTitle')}
            description={i18n.t('createCalendarDescription')}
          />
          {!showCalendars.showLendTicketCalendar && (
            <ShowCalendarButton
              onPress={() =>
                setShowCalendars(() => ({
                  ...showCalendars,
                  showLendTicketCalendar: true,
                }))
              }
            />
          )}
          {showCalendars.showLendTicketCalendar && (
            <Suspense fallback={<Text fontType="body">Henter kalender ...</Text>}>
              <Calendar
                isLendingOutSelected
                selectedPeriods={selectedPeriods.ticket}
                updateSelectedPeriods={updateSelectedPeriods}
                setDatePickerDates={setDatePickerDates}
                datePickerDates={datePickerDates.ticket}
              />
            </Suspense>
          )}
        </KeyboardAwareScrollView>
      </Animated.View>
      <Animated.View style={animatedStyleCreateTicketLender}>
        <KeyboardAwareScrollView
          keyboardShouldPersistTaps="handled"
          keyboardDismissMode="on-drag"
          enableResetScrollToCoords={false}
          extraScrollHeight={EXTRA_SCROLL_HEIGHT}
          style={{ height: '100%', width: '100%' }}
        >
          <Margin marginTop="md" marginBottom="lg">
            <Destinations
              isAllOfSkaeneSelected={isAllOfSkaeneSelected}
              isChecked={isAllOfSkaeneSelected.profile}
              setIsAllOfSkaeneSelected={setIsAllOfSkaeneSelected}
              control={control}
              isLendingOutSelected={false}
              onDestinationSearch={onDestinationSearch}
            />
          </Margin>
          <Margin marginTop="md" marginBottom="lg">
            <Practical hasProvider={false} isLendingOutSelected={false} control={control} />
          </Margin>
          <Margin marginTop="md" marginBottom="lg">
            <TicketTypesAndPayment
              isLendingOutSelected={false}
              toggleTicketTypesAndPayment={toggleTicketTypesAndPayment}
              setToggleTicketTypesAndPayment={setToggleTicketTypesAndPayment}
            />
          </Margin>
          <LabelAndDescription
            title="Vælg en eller flere datoer"
            description="Du kan vælge én dato eller et datointerval."
          />
          {!showCalendars.showLendProfileCalendar && (
            <ShowCalendarButton
              onPress={() =>
                setShowCalendars(() => ({
                  ...showCalendars,
                  showLendProfileCalendar: true,
                }))
              }
            />
          )}
          {showCalendars.showLendProfileCalendar && (
            <Suspense fallback={<Text fontType="body">Henter kalender ...</Text>}>
              <Calendar
                isLendingOutSelected={false}
                selectedPeriods={selectedPeriods.profile}
                datePickerDates={datePickerDates.profile}
                updateSelectedPeriods={updateSelectedPeriods}
                onDestinationSearch={onDestinationSearch}
                setDatePickerDates={setDatePickerDates}
              />
            </Suspense>
          )}
        </KeyboardAwareScrollView>
      </Animated.View>
    </Wrapper>
  );
};

export default CreatePageForm;
