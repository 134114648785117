import React from 'react';
import { useTheme } from 'styled-components/native';
import Icon from '@lendticket/ui/components/molecules/Icon';

const ICON_DIMENSIONS = {
  login: {
    name: 'login',
    width: 229,
    height: 231,
  },
  lendticket: {
    name: 'lendTicketLogo',
    width: 176,
    height: 188,
  },
  introduction: {
    name: 'onboardingIntroduction',
    width: 233,
    height: 226,
  },
  lendorloan: {
    name: 'onboardingLendOrLoan',
    width: 264,
    height: 208,
  },
  lend: {
    name: 'onboardingLend',
    width: 235,
    height: 209,
  },
  loan: {
    name: 'onboardingLoan',
    width: 284,
    height: 225,
  },
  forgotPassword: {
    name: 'forgotPassword',
    width: 303,
    height: 239,
  },
  mailSuccess: {
    name: 'mailSuccess',
    width: 192,
    height: 217,
  },
} as {
  [key: string]: {
    width: number;
    height: number;
  };
};

export interface SvgImageProps {
  name: 'forgotPassword' | 'login' | 'lendticket' | 'introduction' | 'lendorloan' | 'lend' | 'loan';
}

const SvgImage: React.FC<SvgImageProps> = ({ name }) => {
  const { COLORS } = useTheme().settings;

  return <Icon fill={COLORS.WHITE} {...ICON_DIMENSIONS[name]} />;
};

export default SvgImage;
