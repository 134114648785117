import React from 'react';
import styled from 'styled-components/native';
import Wrapper from '@lendticket/ui/components/atoms/Wrapper';
import type { WrapperProps } from '@lendticket/ui/components/atoms/Wrapper';

interface ButtonProps extends WrapperProps {
  onPress: () => void;
  disabled?: boolean;
  activeOpacity?: number;
  parentStyle?: any;
}

const TouchableOpacity = styled.TouchableOpacity<Pick<WrapperProps, 'jc' | 'ai'>>`
  justify-content: ${({ jc }) => jc ?? 'flex-start'};
  align-items: ${({ ai }) => ai ?? 'center'};
`;

const Button: React.FC<ButtonProps> = ({ onPress, disabled, activeOpacity, children, ...rest }) => (
  <TouchableOpacity
    hitSlop={{ top: 5, bottom: 5, left: 5, right: 5 }}
    activeOpacity={activeOpacity}
    disabled={disabled}
    onPress={onPress}
    jc={rest?.jc}
    ai={rest?.ai}
    style={rest?.parentStyle ?? {}}
  >
    <Wrapper {...rest}>{children}</Wrapper>
  </TouchableOpacity>
);

export default Button;
