import { useCallback } from 'react';
import { alertMessage } from '@lendticket/app/helpers/alertMessage';
import { useCreateChat } from './useCreateChat';
import { useUpdateChat } from './useUpdateChat';
import * as routeNames from '@lendticket/app/constants/routeNames';
import api from '@lendticket/app/reducers/slices/api';
import { useNavigation } from '@react-navigation/native';
import { useI18n } from '@lendticket/app/hooks/i18n';

export const useHandleNewMessage = () => {
  const i18n = useI18n();
  const createChat = useCreateChat();
  const updateChat = useUpdateChat();
  const navigation = useNavigation();
  const [sendChatNotification] = api.useSendChatNotificationMutation();

  return useCallback(
    ({ liveMessages, message }: any) => {
      if (message?.trim() === '' || !message?.length || !message) {
        alertMessage(i18n.t('alertMessageCantSendChatMessage'));
      } else if (liveMessages?.messages?.length === 0) {
        createChat({
          data: liveMessages.chatDetails,
          message,
          callback: (res: any) => {
            /* NOTE: Send notification whether its native or web. You never know
            whether the reciever has an application installed as well.
            Furthermore, we only send a notification if the message sender is
            not equal to user */
            const { from, name, text } = res.messages[0] ?? {};
            const { chatParticipantIds } = liveMessages.chatDetails ?? {};

            if (chatParticipantIds?.length > 0) {
              const userIdThatShouldRecieveNotification = chatParticipantIds.filter(
                (id: string) => id !== from,
              )[0];

              sendChatNotification({
                userId: userIdThatShouldRecieveNotification,
                notification: {
                  title: i18n.t('notificationNewMessage', { name }),
                  body: text,
                },
              });
            }
          },
        });
      } else {
        updateChat({
          chatId: liveMessages.chatDetails.id,
          chatDetails: liveMessages.chatDetails,
          message,
          callback: (res: any) => {
            if (!res?.message) {
              /* NOTE: Sideeffect for missing ticket or chat */
              alertMessage(i18n.t('alertMessageChatMessageHasExpired'));
              navigation.navigate(routeNames.DRAWER_AND_TABBAR, {
                screen: routeNames.TABS,
                params: {
                  screen: routeNames.MESSAGES,
                  params: { deletedChatId: liveMessages.chatDetails.id },
                },
              });
            } else {
              /* NOTE: Send notification whether its native or web. You never know
              whether the reciever has an application installed as well.
              Furthermore, we only send a notification if the message sender is
              not equal to user */
              let notificationRecieverUserId;
              const { from, name, text } = res.message ?? {};
              notificationRecieverUserId = liveMessages.chatDetails.messages.filter(
                ({ from: messagesFrom }: any) => messagesFrom !== from,
              )[0]?.from;

              if (notificationRecieverUserId) {
                sendChatNotification({
                  userId: notificationRecieverUserId,
                  notification: {
                    title: i18n.t('notificationNewMessage', { name }),
                    body: text,
                  },
                });
              }
            }
          },
        });
      }
    },
    [createChat, i18n, navigation, sendChatNotification, updateChat],
  );
};
