import React, { useState, useCallback } from 'react';
import * as routeNames from '@lendticket/app/constants/routeNames';
import { NativeAlert } from '@lendticket/ui/components/organisms';
import { navigationRef } from '@lendticket/app/helpers/navigation/config';
import { useI18n } from '@lendticket/app/hooks/i18n';
import { ModalProviderWrapper } from 'contexts';
import { useHandleTicketDeletion, useHandleAddOrRemoveFavorite } from './hooks';

const IS_TICKET_OWNER = 'isTicketOwner';
const IS_UNAUTHORIZED = 'unauthorized';
const ADD_OR_REMOVE_FAVORITE = 'addOrRemoveFavorite';
const DEFAULT_MODAL_STATE = {
  title: '',
  message: '',
  type: '',
  onCloseButtonText: '',
  onConfirmButtonText: '',
  visible: false,
  onClose: undefined,
  onConfirm: undefined,
};

interface ModalStateProps {
  title: string;
  message: string;
  type: string;
  onCloseButtonText: string;
  onConfirmButtonText: string;
  visible: boolean;
  onClose?: () => void;
  onConfirm?: () => void;
}

interface ModalProviderProps {
  children: React.ReactNode;
}

const Modal: React.FC<ModalProviderProps> = ({ children }) => {
  const [modalState, setModalState] = useState<ModalStateProps>(DEFAULT_MODAL_STATE);

  const i18n = useI18n();
  const handleTicketDeletion = useHandleTicketDeletion();
  const handleAddOrRemoveFavorite = useHandleAddOrRemoveFavorite();

  const {
    type,
    title,
    message,
    visible,
    onCloseButtonText,
    onConfirmButtonText,
    onClose,
    onConfirm,
  } = modalState ?? {};

  const derivedOnClose = useCallback(() => {
    if (type === IS_UNAUTHORIZED) {
      navigationRef.current?.navigate(routeNames.FIND_TICKETS_AND_PROFILES);
    }

    if (onClose) {
      onClose();
    }

    setModalState(DEFAULT_MODAL_STATE);
  }, [onClose, type]);

  const derivedOnConfirm = useCallback(() => {
    if (onConfirm) {
      onConfirm();
    } else if (type === ADD_OR_REMOVE_FAVORITE) {
      handleAddOrRemoveFavorite();
    } else if (type === IS_TICKET_OWNER) {
      handleTicketDeletion();
    } else if (type === IS_UNAUTHORIZED) {
      navigationRef.current?.reset({ index: 1, routes: [{ name: routeNames.LOGIN }] });
    }

    setModalState(DEFAULT_MODAL_STATE);
  }, [handleTicketDeletion, handleAddOrRemoveFavorite, onConfirm, type]);

  return (
    <ModalProviderWrapper value={{ setModalState }}>
      {children}
      <NativeAlert
        title={title}
        visible={visible}
        onCloseButtonText={onCloseButtonText ?? i18n.t('goBack')}
        onConfirmButtonText={onConfirmButtonText ?? ''}
        message={message ?? ''}
        onClose={derivedOnClose}
        onConfirm={derivedOnConfirm}
      />
    </ModalProviderWrapper>
  );
};

export default Modal;
