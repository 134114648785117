import { useCallback } from 'react';
import { alertMessage } from '@lendticket/app/helpers/alertMessage';
import { useI18n } from '@lendticket/app/hooks/i18n';

export const usePasswordValidation = () => {
  const i18n = useI18n();

  return useCallback(
    (data: any) => {
      const { password, repeatPassword } = data ?? {};

      if (password === '' || !password) {
        alertMessage(i18n.t('pleaseProvidePasswordVariantOne'));
      } else if (repeatPassword === '') {
        alertMessage(i18n.t('pleaseConfirmPasswordVariantOne'));
      } else if (password !== repeatPassword) {
        alertMessage(i18n.t('pleaseEnsurePasswordsAreIdentical'));
      } else {
        return true;
      }
    },
    [i18n],
  );
};
