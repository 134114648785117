import { isAndroid } from '@lendticket/app/helpers/platform';
import React, { useEffect } from 'react';
import { ColorValue, Platform } from 'react-native';
import Animated, {
  useAnimatedStyle,
  useAnimatedKeyboard,
  withTiming,
  Easing,
  KeyboardState,
} from 'react-native-reanimated';
import { Wrapper } from '../../atoms';

interface AnimatedKeyboardAvoidingViewProps {
  backgroundColor?: ColorValue;
  // Offset the keyboard height by this amount.
  // Useful when view has for instance a tab bar which makes the offset of the keyboard too high.
  offset?: number;
  children: React.ReactNode;
}

const AnimatedKeyboardAvoidingView: React.FC<AnimatedKeyboardAvoidingViewProps> = ({
  backgroundColor,
  offset = 0,
  children,
}) => {
  const keyboard = useAnimatedKeyboard();

  useEffect(() => {
    /* TODO: Bug on Android. Reset behaviour for keyboard state. On Android the state
    quickly goes from open to closed. This is a quick fix */
    if (Platform.OS === 'android') {
      keyboard.state.value = KeyboardState.CLOSED;
      keyboard.height.value = 0;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const animatedStyle = useAnimatedStyle(() => ({
    width: '100%',
    backgroundColor,
    transform: [
      {
        // Set the translateY to the keyboard height + offset, but capped to not go above 0,
        // so it doesn't go below the bottom of the screen.
        translateY: withTiming(Math.min(-keyboard.height.value + offset, 0), {
          duration: 20,
          easing: Easing.ease,
        }),
      },
    ],
  }));

  return (
    <Wrapper jc="center" ai="center" style={{ width: '100%' }}>
      <Animated.View style={isAndroid ? {} : animatedStyle}>{children}</Animated.View>
    </Wrapper>
  );
};

export default AnimatedKeyboardAvoidingView;
