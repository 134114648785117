import React from 'react';
import { TouchableOpacity } from 'react-native';
import { useTheme } from 'styled-components/native';
import Animated, { useAnimatedStyle } from 'react-native-reanimated';
import { Wrapper, Text, Margin } from '@lendticket/ui/components/atoms';
import Icon from '@lendticket/ui/components/molecules/Icon';
import { useI18n } from '@lendticket/app/hooks/i18n';

const BORDER_RADIUS = 7;

const SHOW_DETAILS_DEGREES_OPEN = 90;
const FONT_WEIGHT_DEFAULT = 'bold';
const ICON_DIMENSIONS = 14;
const SHOW_DETAILS_DEGREES_CLOSED = 270;

interface DetailsProps {
  onPress: () => void;
  showDetailsDegrees: Animated.SharedValue<number>;
  shouldShowDetails: Animated.SharedValue<boolean>;
  isDetailsVisible: boolean;
}

const Details: React.FC<DetailsProps> = ({
  isDetailsVisible,
  showDetailsDegrees,
  shouldShowDetails,
  onPress,
}) => {
  const i18n = useI18n();
  const { settings, deviceSizes } = useTheme();
  const { isMobile } = deviceSizes;
  const { COLORS } = settings;

  const animatedStyleShowDetails = useAnimatedStyle(() => ({
    transform: [
      {
        rotate: `${showDetailsDegrees.value}deg`,
      },
    ],
  }));

  return (
    <TouchableOpacity
      style={{
        width: '100%',
        backgroundColor: COLORS.BACKGROUND_LIGHT,
        borderBottomRightRadius: isDetailsVisible ? 0 : BORDER_RADIUS,
        borderBottomLeftRadius: isDetailsVisible ? 0 : BORDER_RADIUS,
      }}
      hitSlop={{ top: 5, bottom: 5, left: 5, right: 5 }}
      onPress={() => {
        onPress();
        shouldShowDetails.value = !shouldShowDetails.value;
        showDetailsDegrees.value =
          showDetailsDegrees.value === SHOW_DETAILS_DEGREES_OPEN
            ? SHOW_DETAILS_DEGREES_CLOSED
            : SHOW_DETAILS_DEGREES_OPEN;
      }}
    >
      <Wrapper
        fd="row"
        style={{
          paddingTop: 2,
          paddingBottom: 2,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Text
          fontType={isMobile ? 'subtitle' : 'body'}
          style={{
            fontWeight: isMobile ? undefined : FONT_WEIGHT_DEFAULT,
            color: COLORS.TEXT_LIGHT_PURPLE,
          }}
        >
          {i18n.t('showDetails')}
        </Text>
        <Margin marginLeft="xxs" />
        <Animated.View style={animatedStyleShowDetails}>
          <Icon
            name="arrow"
            fill={COLORS.TEXT_LIGHT_PURPLE}
            height={ICON_DIMENSIONS}
            width={ICON_DIMENSIONS}
          />
        </Animated.View>
      </Wrapper>
    </TouchableOpacity>
  );
};

export default Details;
