import React, { useMemo, useCallback } from 'react';
import { useNavigation } from '@react-navigation/native';
import { OnboardingSliderPage } from '@lendticket/ui/components/templates';
import * as routeNames from '@lendticket/app/constants/routeNames';
import Landing from './Landing';
import Information from './Information';
import LendOrLoan from './LendOrLoan';
import Lend from './Lend';
import Loan from './Loan';
import { useI18n } from '@lendticket/app/hooks/i18n';

const getFooterActionTexts = (i18n: any) => [
  { textLeft: i18n.t('no'), textRight: i18n.t('yes') },
  { textLeft: i18n.t('tickets'), textRight: i18n.t('continue') },
  { textLeft: i18n.t('loanOut'), textRight: i18n.t('loan') },
  { textLeft: i18n.t('tickets'), textRight: i18n.t('login') },
  { textLeft: i18n.t('tickets'), textRight: i18n.t('login') },
];

const screens = [Landing, Information, LendOrLoan, Loan, Lend];

const Introduction: React.FC = () => {
  const i18n = useI18n();
  const navigation = useNavigation();
  const footerActionTexts = useMemo(() => getFooterActionTexts(i18n), [i18n]);

  const navigateToLoginScreen = useCallback(() => {
    navigation.replace(routeNames.LOGIN);
  }, [navigation]);

  const navigateToTicketsAndProfilesOverview = useCallback(() => {
    navigation.replace(routeNames.DRAWER_AND_TABBAR, {
      screen: routeNames.TABS,
      params: { screen: routeNames.FIND_TICKETS_AND_PROFILES },
    });
  }, [navigation]);

  return (
    <OnboardingSliderPage
      title="Lend Ticket"
      items={screens}
      footerActionTexts={footerActionTexts}
      navigateToLoginScreen={navigateToLoginScreen}
      navigateToTicketsAndProfilesOverview={navigateToTicketsAndProfilesOverview}
    />
  );
};

export default Introduction;
