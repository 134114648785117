import React from 'react';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import * as routeNames from '@lendticket/app/constants/routeNames';
import FindTicketsAndProfiles from '@lendticket/app/areas/FindTicketsAndProfiles';
import Messages from '@lendticket/app/areas/Messages';
import Home from '@lendticket/app/areas/Home';
import Create from '@lendticket/app/areas/Create';
import Header from '@lendticket/app/containers/Header';
import TabBarComponent from '@lendticket/app/areas/TabBar';
import Favorites from '@lendticket/app/areas/Favorites';
import { isWeb } from '@lendticket/app/helpers/platform';
import { useI18n } from '@lendticket/app/hooks/i18n';

const Tab = createBottomTabNavigator();

const TabBar: React.FC = () => {
  const i18n = useI18n();

  return (
    <Tab.Navigator
      initialRouteName={routeNames.HOME}
      tabBar={props => !isWeb && <TabBarComponent {...props} />}
      screenOptions={{
        // eslint-disable-next-line react/no-unstable-nested-components
        header: props => <Header {...props} />,
        headerTransparent: true,
        tabBarHideOnKeyboard: true,
      }}
    >
      <Tab.Screen
        name={routeNames.FAVORITES}
        component={Favorites}
        options={{ title: i18n.t('tabBarTitleFavorites') }}
      />
      <Tab.Screen
        name={routeNames.HOME}
        component={Home}
        options={{ title: i18n.t('tabBarTitleHome') }}
      />
      <Tab.Screen
        name={routeNames.CREATE}
        component={Create}
        options={{ title: i18n.t('tabBarTitleCreate') }}
      />
      <Tab.Screen
        name={routeNames.FIND_TICKETS_AND_PROFILES}
        component={FindTicketsAndProfiles}
        options={{ title: i18n.t('tabBarTitleFindTicket') }}
      />
      <Tab.Screen
        name={routeNames.MESSAGES}
        component={Messages}
        options={{ title: i18n.t('tabBarTitleMessages') }}
      />
    </Tab.Navigator>
  );
};

export default TabBar;
