import React, { useState, useCallback, useEffect, useContext } from 'react';
import { shallowEqual } from 'react-redux';
import * as routeNames from '@lendticket/app/constants/routeNames';
import { useNavigation, useRoute, useIsFocused } from '@react-navigation/native';
import { ChatPage } from '@lendticket/ui/components/templates';
import { useAppSelector } from '@lendticket/app/hooks/redux';
import { userSelector } from '@lendticket/app/reducers/slices/user';
import api from '@lendticket/app/reducers/slices/api';
import { useGetFreshChatInformation } from './hooks/useGetFreshChatInformation';
import { useHandleNewMessage } from './hooks/useHandleNewMessage';
import { SocketContext } from '../../contexts';
import { useAppDispatch } from '@lendticket/app/helpers/redux/store';
import { useHandleUserEvaluations } from './hooks/useHandleUserEvaluations';
import { isWeb } from '@lendticket/app/helpers/platform';
import { chatsSelector, chatsActions } from '@lendticket/app/reducers/slices/chats';
import type { RootState } from '@lendticket/app/helpers/redux';
import { useHeadlessTask } from '@lendticket/ui/hooks';

const INITIAL_STATE = {
  deletedChatId: undefined,
  deletedTicketId: undefined,
  deletedProfileId: undefined,
  listener: {
    lastMessage: {
      message: undefined,
      messageId: undefined,
    },
    hasMessages: undefined,
  },
  newMessages: {
    chatId: undefined,
    message: undefined,
    messageId: [],
  },
  liveMessages: {
    chatDetails: {},
    allMessages: [],
    messages: [],
    messageIds: [],
    newMessageIds: [],
    hasMessages: true,
    isChatDead: false,
    isInitiated: false,
    checkForUpdates: false,
  },
};

const chatSelector = (state: RootState) => ({
  isChatDetailsVisible: chatsSelector(state).isChatDetailsVisible,
  user: userSelector(state),
});

const Chat: React.FC = () => {
  const { createSocket } = useContext(SocketContext);
  const [runHeadlessTask, setRunHeadlessTask] = useState(false);
  const [newMessages, setNewMessages] = useState(INITIAL_STATE.newMessages);
  const [liveMessages, setLiveMessages] = useState(INITIAL_STATE.liveMessages);
  const [deletedChatId, setDeletedChatId] = useState<string | undefined>(
    INITIAL_STATE.deletedChatId,
  );
  const [deletedProfileId, setDeletedProfileId] = useState<string | undefined>(
    INITIAL_STATE.deletedProfileId,
  );
  const [deletedTicketId, setDeletedTicketId] = useState<string | undefined>(
    INITIAL_STATE.deletedTicketId,
  );

  const dispatch = useAppDispatch();
  const navigation = useNavigation();
  const { ticketId, profileId, favoriteId, userId, chatId }: any = useRoute().params ?? {};
  const { user, isChatDetailsVisible } = useAppSelector(chatSelector, shallowEqual);
  const isFocused = useIsFocused();

  const { getLendTicketByTicketId, getLendProfileByProfileId, getChatByChatIdAndUserId } =
    api.endpoints;

  const isMissingNewMessageId = newMessages.messageId.some(
    id => !liveMessages.messageIds.includes(id),
  );

  const getFreshChatInformation = useGetFreshChatInformation();
  const handleNewMessage = useHandleNewMessage();
  const handleUserEvaluations = useHandleUserEvaluations();

  /* NOTE: Initiating */
  const initiate = useCallback(async () => {
    let data;

    if (chatId && userId) {
      const { refetch } = dispatch(getChatByChatIdAndUserId.initiate({ chatId, userId }));
      const { data: chatData } = await refetch();

      data = chatData?.chat;

      /* NOTE: This scope only on web on hard refresh */
      if (!data && isWeb) {
        const { data: ticketOrProfileData } = await dispatch(
          ticketId
            ? getLendTicketByTicketId.initiate(ticketId)
            : getLendProfileByProfileId.initiate(profileId),
        );

        data = await getFreshChatInformation({
          data: ticketId ? ticketOrProfileData?.ticket : ticketOrProfileData?.profile,
          profileId,
        });
      }
    } else if (ticketId && !chatId) {
      const { refetch } = dispatch(getLendTicketByTicketId.initiate(ticketId));
      const { data: ticketData } = await refetch();
      data = await getFreshChatInformation({ data: ticketData?.ticket, profileId });
    } else if (profileId && !chatId) {
      const { refetch } = dispatch(getLendProfileByProfileId.initiate(profileId));
      const { data: profileData } = await refetch();
      data = await getFreshChatInformation({ data: profileData?.profile, profileId });
    } else if (favoriteId && !chatId) {
      data = await getFreshChatInformation({ favoriteId });
    }

    if (!chatId) {
      navigation.setParams({
        chatId: data.id,
      });
    }

    const messages = data?.messages;

    setLiveMessages({
      ...liveMessages,
      chatDetails: data,
      messages: messages,
      messageIds: messages?.map(({ id }: any) => id),
      hasMessages: messages?.length > 0,
      isInitiated: true,
      checkForUpdates: false,
    });
  }, [
    chatId,
    userId,
    ticketId,
    profileId,
    favoriteId,
    liveMessages,
    getChatByChatIdAndUserId,
    getLendTicketByTicketId,
    getLendProfileByProfileId,
    navigation,
    getFreshChatInformation,
    dispatch,
  ]);

  /* NOTE: Initiating and updating behaviour based on new tickets */
  useEffect(() => {
    if (isMissingNewMessageId && newMessages.chatId === chatId) {
      const updatedSetOfMessages: any = [...liveMessages.messages, newMessages.message];
      setNewMessages(INITIAL_STATE.newMessages);
      setLiveMessages({
        ...liveMessages,
        messages: updatedSetOfMessages,
        messageIds: updatedSetOfMessages.map(({ id }: any) => id),
        newMessageIds: newMessages.messageId,
        hasMessages: true,
      });
    }
  }, [isMissingNewMessageId, liveMessages, newMessages, user, chatId]);

  /* NOTE: Handle ticket deletion */
  useEffect(() => {
    if (deletedTicketId && liveMessages.chatDetails.ticketId === deletedTicketId) {
      setDeletedTicketId(INITIAL_STATE.deletedTicketId);
      setLiveMessages({
        ...INITIAL_STATE.liveMessages,
        isChatDead: true,
      });
    } else if (deletedProfileId && liveMessages.chatDetails.profileId === deletedProfileId) {
      setDeletedProfileId(INITIAL_STATE.deletedProfileId);
      setLiveMessages({
        ...INITIAL_STATE.liveMessages,
        isChatDead: true,
      });
    } else if (deletedChatId && liveMessages.chatDetails.id === deletedChatId) {
      setDeletedChatId(INITIAL_STATE.deletedChatId);
      setLiveMessages({
        ...INITIAL_STATE.liveMessages,
        isChatDead: true,
      });
    }
  }, [deletedChatId, deletedProfileId, deletedTicketId, liveMessages]);

  const onHandleNewMessage = useCallback(
    (message: string) => handleNewMessage({ liveMessages, message }),
    [liveMessages, handleNewMessage],
  );

  /* NOTE: Handle unmount */
  useEffect(() => {
    if (!isFocused && !isChatDetailsVisible) {
      dispatch(chatsActions.resetChatDetailsVisibility());
    }
  }, [isFocused, dispatch, isChatDetailsVisible]);

  useEffect(() => {
    if (!liveMessages.isInitiated) {
      initiate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const socketId = 'socketChat';
    const socketChat = createSocket(socketId);

    socketChat.on('foundChatMessage', ({ chatId: derivedChatId, message }: any) => {
      setNewMessages({
        chatId: derivedChatId,
        message,
        messageId: [message.id] as any,
      });
    });

    socketChat.on('chatDeletion', ({ chatId: derivedChatId }: { chatId: string }) => {
      setDeletedChatId(derivedChatId);
    });

    socketChat.on('profileDeletion', ({ profileId: derivedProfileId }: { profileId: string }) => {
      setDeletedProfileId(derivedProfileId);
    });

    socketChat.on('ticketDeletion', ({ ticketId: derivedTicketId }: { ticketId: string }) => {
      setDeletedTicketId(derivedTicketId);
    });

    return () => {
      socketChat.off('foundChatMessages');
      socketChat.off('chatDeletion');
      socketChat.off('profileDeletion');
      socketChat.off('ticketDeletion');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const headlessTask = useCallback(async () => {
    setRunHeadlessTask(false);

    if (liveMessages?.messages?.length === 0) {
      return;
    }

    initiate();
  }, [liveMessages, initiate]);

  useEffect(() => {
    if (runHeadlessTask && chatId) {
      headlessTask();
    }
  }, [runHeadlessTask, chatId, headlessTask]);

  useHeadlessTask({
    matchRoute: routeNames.CHAT,
    handleHeadlessTask: () => {
      setRunHeadlessTask(true);
    },
  });

  return (
    <ChatPage
      chat={liveMessages.chatDetails}
      messages={liveMessages?.messages}
      newMessageIds={liveMessages.newMessageIds}
      userId={user?.id}
      userName={user?.name}
      isChatCreated={liveMessages.messages?.length > 0}
      isChatDetailsVisible={isChatDetailsVisible}
      handleNewMessage={onHandleNewMessage}
      handleUserEvaluations={(evaluationType: string) => {
        handleUserEvaluations({
          evaluationType,
          liveMessages,
          userId,
          callback: updateParams => {
            setLiveMessages({
              ...liveMessages,
              chatDetails: {
                ...liveMessages.chatDetails,
                ...updateParams,
              },
            });
          },
        });
      }}
      isChatDead={liveMessages.isChatDead}
    />
  );
};

export default Chat;
