import React, { useMemo, useContext, useCallback } from 'react';
import { ParamListBase, Route } from '@react-navigation/native';
import { Header as HeaderComponent } from '@lendticket/ui/components/organisms';
import * as routeNames from '@lendticket/app/constants/routeNames';
import { chatsSelector, chatsActions } from '@lendticket/app/reducers/slices/chats';
import { useAppDispatch, RootState } from '@lendticket/app/helpers/redux/store';
import { useAppSelector } from '@lendticket/app/hooks/redux';
import { userSelector } from '@lendticket/app/reducers/slices/user';
import { shallowEqual } from 'react-redux';
import { ticketsSelector } from '@lendticket/app/reducers/slices/tickets';
import { useI18n } from '@lendticket/app/hooks/i18n';
import { ModalContext } from 'contexts';
import { favoriteSelector } from '@lendticket/app/reducers/slices/api/chats';
import type { BottomTabNavigationProp } from '@react-navigation/bottom-tabs';
import type { StackNavigationProp } from '@react-navigation/stack';

const getTitles = (i18n: any) =>
  ({
    [routeNames.FIND_TICKETS_AND_PROFILES]: i18n.t('titleFindTickets'),
    [routeNames.CHAT]: i18n.t('titleChat'),
    [routeNames.MESSAGES]: i18n.t('titleMessages'),
    [routeNames.LOGIN]: i18n.t('titleLogin'),
    [routeNames.ONBOARDING]: i18n.t('titleOnboarding'),
    [routeNames.ONBOARDING_INTRODUCTION]: i18n.t('titleOnboardingIntroduction'),
    [routeNames.HOME]: i18n.t('titleHome'),
    [routeNames.CREATE]: i18n.t('titleCreate'),
    [routeNames.FORGOT_PASSWORD]: i18n.t('titleForgotPassword'),
    [routeNames.EDIT]: i18n.t('titleEdit'),
    [routeNames.UPDATE_USER]: i18n.t('titleUpdateUser'),
    [routeNames.UPCOMING]: i18n.t('titleUpcoming'),
    [routeNames.PRIVACY_POLICY]: i18n.t('titlePrivacyPolicy'),
    [routeNames.RESET_PASSWORD]: i18n.t('titleResetPassword'),
    [routeNames.SUPPORT]: i18n.t('titleSupport'),
    [routeNames.SUNSET]: i18n.t('titleUpdateRequired'),
    [routeNames.FAVORITES]: i18n.t('titleFavorites'),
  }) as { [key: string]: string };

interface HeaderProps {
  navigation:
    | StackNavigationProp<ParamListBase, string, undefined>
    | BottomTabNavigationProp<ParamListBase, string, undefined>;
  route: Route<string, object | undefined>;
}

const headerSelctor = (state: RootState) => ({
  tickets: ticketsSelector(state),
  chats: chatsSelector(state),
  user: userSelector(state),
  favorites: favoriteSelector(state),
});

const Header: React.FC<HeaderProps> = ({ navigation, route }) => {
  const i18n = useI18n();
  const { setModalState } = useContext(ModalContext);
  const titles = useMemo(() => getTitles(i18n), [i18n]);
  const isRouteLive = [routeNames.FIND_TICKETS_AND_PROFILES, routeNames.MESSAGES].includes(
    route.name,
  );

  const isRouteFavorite = navigation
    .getState()
    ?.routes?.filter(({ name }) => name === routeNames.CHAT)[0]?.params?.isFavorite;
  const isRouteChatOrHeader = [routeNames.CHAT, routeNames.HOME].includes(route.name);
  const isRouteLogin = routeNames.LOGIN === route.name;
  const isRouteChat = routeNames.CHAT === route.name;
  const isRoutePrivacyPolicy = routeNames.PRIVACY_POLICY === route.name;
  const isRouteForgotPassword = routeNames.FORGOT_PASSWORD === route.name;
  const isRouteResetPassword = routeNames.RESET_PASSWORD === route.name;
  const isRouteOnboarding = routeNames.ONBOARDING === route.name;
  const shouldGoBackToHome = [
    routeNames.FORGOT_PASSWORD,
    routeNames.UPDATE_USER,
    routeNames.UPCOMING,
    routeNames.SUPPORT,
  ].includes(route.name);
  const routeHasTranslationPossibility = [
    routeNames.FAVORITES,
    routeNames.FIND_TICKETS_AND_PROFILES,
    routeNames.MESSAGES,
    routeNames.CREATE,
    routeNames.HOME,
    routeNames.ONBOARDING,
    routeNames.LOGIN,
  ].includes(route.name);
  const isModal = [
    routeNames.SUPPORT,
    routeNames.UPDATE_USER,
    routeNames.EDIT,
    routeNames.CHAT,
    routeNames.UPCOMING,
    routeNames.FORGOT_PASSWORD,
    routeNames.PRIVACY_POLICY,
    routeNames.ONBOARDING,
    routeNames.SUNSET,
  ].includes(route.name);
  const { chats, user, tickets, favorites } = useAppSelector(headerSelctor, shallowEqual);
  const { isChatDetailsVisible } = chats ?? {};
  const dispatch = useAppDispatch();

  const onPressChatDetailsVisibility = useCallback(() => {
    dispatch(chatsActions.setChatDetailsVisibility(!isChatDetailsVisible));
  }, [dispatch, isChatDetailsVisible]);

  const onPressLendedOut = useCallback(() => {
    setModalState({
      visible: true,
      type: 'isTicketOwner',
      title: i18n.t('deleteTicketInformationTitle'),
      onConfirmButtonText: i18n.t('deleteTicket'),
      message: i18n.t('deleteTicketInformation'),
    });
  }, [setModalState, i18n]);

  const onPressAddToFavorite = useCallback(() => {
    setModalState({
      visible: true,
      type: 'addOrRemoveFavorite',
      title: favorites?.isFavorite ? i18n.t('removeFavoriteTitle') : i18n.t('addFavoriteTitle'),
      onConfirmButtonText: favorites?.isFavorite
        ? i18n.t('removeFavoriteOnConfirmText')
        : i18n.t('addFavoriteOnConfirmText'),
      message: i18n.t('addOrRemoveFavoriteDescription'),
    });
  }, [favorites, i18n, setModalState]);

  const goBack = useCallback(() => {
    if (isRouteForgotPassword || isRouteResetPassword) {
      navigation.navigate(routeNames.LOGIN);
    } else if (isRoutePrivacyPolicy) {
      if (Object.values(user ?? {}).length > 0) {
        navigation.navigate(routeNames.DRAWER_AND_TABBAR, {
          screen: routeNames.TABS,
          params: { screen: routeNames.HOME },
        });
      } else {
        navigation.navigate(routeNames.ONBOARDING);
      }
    } else if (shouldGoBackToHome) {
      navigation.navigate(routeNames.DRAWER_AND_TABBAR, {
        screen: routeNames.TABS,
        params: { screen: routeNames.HOME },
      });
    } else {
      navigation.goBack();
    }
  }, [
    isRouteForgotPassword,
    isRouteResetPassword,
    isRoutePrivacyPolicy,
    shouldGoBackToHome,
    navigation,
    user,
  ]);

  return (
    <HeaderComponent
      isRouteFavorite={isRouteFavorite}
      isRouteLive={isRouteLive}
      isRouteChatOrHeader={isRouteChatOrHeader}
      isRouteChat={isRouteChat}
      isChatDetailsVisible={isChatDetailsVisible}
      isRouteLogin={isRouteLogin}
      isRouteOnboarding={isRouteOnboarding}
      isFavorite={favorites?.isFavorite}
      routeHasTranslationPossibility={routeHasTranslationPossibility}
      isTicketOwner={tickets.isTicketOwner}
      isModal={isModal}
      titles={titles}
      onPressAddToFavorite={onPressAddToFavorite}
      onPressLendedOut={onPressLendedOut}
      onPressChatDetailsVisibility={onPressChatDetailsVisibility}
      goBack={goBack}
      route={route}
      navigation={navigation}
    />
  );
};

export default Header;
