import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import * as routeNames from '@lendticket/app/constants/routeNames';
import ProvideEmail from './ProvideEmail';
import MailSent from './MailSent';

const routes = [
  {
    name: routeNames.PROVIDE_EMAIL,
    component: ProvideEmail,
  },
  { name: routeNames.EMAIL_SENT, component: MailSent },
];

const Stack = createNativeStackNavigator();

export default () => (
  <Stack.Navigator screenOptions={{ headerShown: false }}>
    {routes.map(({ name, component }, i) => (
      <Stack.Screen key={`${name}-${i}`} name={name} component={component} />
    ))}
  </Stack.Navigator>
);
