import React from 'react';
import { DefaultPage } from '@lendticket/ui/components/templates';
import { useI18n } from '@lendticket/app/hooks/i18n';

const LendOrLoan: React.FC = () => {
  const i18n = useI18n();

  return (
    <DefaultPage
      teaserTitle={i18n.t('onboardingLendOrLoanTitle')}
      teaserText={i18n.t('onboardingLendOrLoanText')}
      svgName="lendorloan"
    />
  );
};

export default LendOrLoan;
