import React from 'react';
import { useTheme } from 'styled-components/native';
import { LoginPage } from '@lendticket/ui/components/templates';
import { Wrapper } from '@lendticket/ui/components/atoms';
import Animated, { interpolate, useAnimatedStyle, useSharedValue } from 'react-native-reanimated';
import LoginForm from './LoginForm';
import CreateUserForm from './CreateUserForm';
import { useLoginActions } from './hooks/useLoginActions';
import AppAndPlayStore from './AppAndPlayStore';
import { loadingActions } from '@lendticket/app/reducers/slices/loading';
import { useAppDispatch } from '@lendticket/app/helpers/redux/store';
import { useI18n } from '@lendticket/app/hooks/i18n';
import SeasonalLottie from './SeasonalLottie';

const Login: React.FC = () => {
  const i18n = useI18n();
  const translationX = useSharedValue(0);
  const swiperWidth = useSharedValue(0);
  const { settings, deviceSizes, windowDimensions } = useTheme();

  const dispatch = useAppDispatch();
  const loginActions = useLoginActions({
    startLoading: ({ isCancelable }: { isCancelable?: boolean }) => {
      dispatch(
        loadingActions.setLoadingContent({
          isLoading: true,
          isCancelable: isCancelable ?? false,
          hasBackgroundColor: true,
          indicator: 'circle',
          text: i18n.t('loggingIn'),
        }),
      );
    },
  });

  const { isMobile } = deviceSizes;
  const { windowWidth } = windowDimensions;
  const { SPACINGS, CONSTANTS } = settings;
  const { DESKTOP_TABLET_WIDTH } = CONSTANTS;
  const derivedWidth = (isMobile ? windowWidth : DESKTOP_TABLET_WIDTH) - SPACINGS.xl * 2;

  const animatedStyleLoginForm = useAnimatedStyle(() => ({
    position: 'absolute',
    width: derivedWidth,
    opacity: interpolate(translationX.value, [0, swiperWidth.value / 2], [1, 0]),
    transform: [
      {
        translateX: interpolate(
          translationX.value,
          [0, swiperWidth.value / 1.5],
          [0, -derivedWidth],
        ),
      },
    ],
  }));

  const animatedStyleCreateUserForm = useAnimatedStyle(() => ({
    width: derivedWidth,
    opacity: interpolate(translationX.value, [0, swiperWidth.value / 2], [0, 1]),
    transform: [
      {
        translateX: interpolate(translationX.value, [0, swiperWidth.value], [derivedWidth, 0]),
      },
    ],
  }));

  return (
    <LoginPage
      animatedValues={{ translationX, swiperWidth }}
      inputFieldsComponent={
        <Wrapper>
          <Animated.View style={animatedStyleLoginForm}>
            <LoginForm actions={loginActions} />
            <AppAndPlayStore />
            <SeasonalLottie />
          </Animated.View>
          <Animated.View style={animatedStyleCreateUserForm}>
            <CreateUserForm
              actions={Object.keys(loginActions).reduce((obj, item, i) => {
                if (item !== 'forget') {
                  obj = {
                    ...obj,
                    [item]: Object.values(loginActions)[i],
                  };
                }

                return obj;
              }, {})}
            />
            <AppAndPlayStore />
          </Animated.View>
        </Wrapper>
      }
    />
  );
};

export default Login;
