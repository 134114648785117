import React from 'react';
import { ActivityIndicator } from 'react-native';
import Animated, {
  useAnimatedGestureHandler,
  interpolate,
  useAnimatedStyle,
  useSharedValue,
} from 'react-native-reanimated';
import { PanGestureHandlerGestureEvent, PanGestureHandler } from 'react-native-gesture-handler';
import styled, { useTheme } from 'styled-components/native';
import {
  getPanOnActive,
  getPanOnStart,
  getPanOnEnd,
} from '@lendticket/ui/components/organisms/CardGesture/gestures';
import { Text, Margin } from '@lendticket/ui/components/atoms';
import TapGestureHandler from '@lendticket/ui/components/organisms/TapGestureHandler';

const FONT_WEIGHT_DESKTOP = 'bold';
const FONT_SIZE_DESKTOP = 20;

interface AnimatedGHContext {
  [key: string]: number;
  startX: number;
  startY: number;
}

interface CardGestureProps {
  id?: string;
  hiddenText: string;
  height: number;
  children: React.ReactNode;
  onPressAction?: (id: string) => void;
  onSwipeAction?: (id: string, isLoading: Animated.SharedValue<boolean>) => void;
}

export interface GestureParams {
  startX: Animated.SharedValue<number>;
  prevDragX: Animated.SharedValue<number>;
  dragX: Animated.SharedValue<number>;
  translationX: Animated.SharedValue<number>;
  cardWidth: Animated.SharedValue<number>;
  textWidth: Animated.SharedValue<number>;
  isLoading: Animated.SharedValue<boolean>;
  panningDirection: Animated.SharedValue<string>;
  onSwipeCallback?: () => void;
}

const View = styled.View<{ height?: number }>`
  width: 100%;
  ${({ height }) => {
    if (height) {
      return `height: ${height}px`;
    }
  }}
`;

const CardGesture: React.FC<CardGestureProps> = ({
  id,
  isLendProfile,
  height,
  hiddenText,
  onPressAction,
  onSwipeAction,
  children,
}) => {
  const { settings, deviceSizes } = useTheme();
  const { isMobile } = deviceSizes;
  const { COLORS } = settings;

  const isLoading = useSharedValue(false);
  const startX = useSharedValue(0);
  const prevDragX = useSharedValue(0);
  const dragX = useSharedValue(0);
  const translationX = useSharedValue(0);
  const cardHeight = useSharedValue(0);
  const cardWidth = useSharedValue(0);
  const textWidth = useSharedValue(0);
  const textHeight = useSharedValue(0);
  const panningDirection = useSharedValue('none');

  const fontStyle = isMobile
    ? {}
    : { fontWeight: FONT_WEIGHT_DESKTOP, fontSize: FONT_SIZE_DESKTOP };

  const gestureParams = {
    startX,
    prevDragX,
    dragX,
    translationX,
    panningDirection,
    cardWidth,
    textWidth,
    isLoading,
    onSwipeCallback: onSwipeAction
      ? () => {
          if (onSwipeAction && id) {
            onSwipeAction({ id, isLendProfile, isLoading });
          }
        }
      : undefined,
  };

  const animatedLoadingStyle = useAnimatedStyle(() => ({
    display: isLoading.value ? 'flex' : 'none',
    position: 'absolute',
    alignContent: 'center',
    justifyContent: 'center',
    zIndex: 9,
    height: cardHeight.value,
    width: cardWidth.value,
  }));

  const animatedStyle = useAnimatedStyle(() => ({
    position: 'relative',
    zIndex: 9,
    transform: [
      {
        translateX: translationX.value,
      },
    ],
  }));

  const animatedStyleHiddenText = useAnimatedStyle(() => ({
    position: 'absolute',
    width: '100%',
    top: cardHeight.value / 2 - textHeight.value / 2,
    justifyContent: 'center',
    alignItems: 'flex-end',
    opacity: interpolate(translationX.value, [0, -textWidth.value], [0, 1]),
  }));

  const panOnStart = getPanOnStart(gestureParams);
  const panOnActive = getPanOnActive(gestureParams);
  const panOnEnd = getPanOnEnd(gestureParams);

  const gestureHandler = useAnimatedGestureHandler<
    PanGestureHandlerGestureEvent,
    AnimatedGHContext
  >({
    onStart: panOnStart,
    onActive: panOnActive,
    onEnd: panOnEnd,
  });

  return (
    <TapGestureHandler onPress={onPressAction}>
      <Animated.View style={{ flex: 1 }}>
        <View height={height}>
          <PanGestureHandler activeOffsetX={[-10, 10]} onGestureEvent={gestureHandler}>
            <Animated.View
              onLayout={e => {
                if (cardWidth.value === 0) {
                  cardHeight.value = e.nativeEvent.layout.height;
                  cardWidth.value = e.nativeEvent.layout.width;
                }
              }}
              style={animatedStyle}
            >
              <Animated.View style={animatedLoadingStyle}>
                <ActivityIndicator size="large" />
              </Animated.View>
              {children}
            </Animated.View>
          </PanGestureHandler>
          <Animated.View style={animatedStyleHiddenText}>
            <Margin marginRight="md" marginTop="xs">
              <View
                onLayout={e => {
                  if (textWidth.value === 0) {
                    textWidth.value = e.nativeEvent.layout.width;
                    textHeight.value = e.nativeEvent.layout.height;
                  }
                }}
              >
                <Text
                  fontType={isMobile ? 'body' : 'h3'}
                  style={{ color: COLORS.WHITE, ...fontStyle }}
                >
                  {hiddenText}
                </Text>
              </View>
            </Margin>
          </Animated.View>
        </View>
      </Animated.View>
    </TapGestureHandler>
  );
};

export default CardGesture;
