import { useCallback } from 'react';
import api from '@lendticket/app/reducers/slices/api';
import { alertMessage } from '@lendticket/app/helpers/alertMessage';
import { useI18n } from '@lendticket/app/hooks/i18n';

interface useHandleUserEvaluationParams {
  evaluationType: string;
  liveMessages: Record<string, any>;
  userId: string;
  callback: (updateParams: {
    chatOwnerRecommendations: any;
    hasBeenEvaluatedBad: boolean;
    hasBeenEvaluatedGood: boolean;
  }) => void;
}

export const useHandleUserEvaluations = () => {
  const i18n = useI18n();
  const [updateUserEvaluations] = api.useUpdateUserEvaluationsMutation();

  return useCallback(
    async ({ evaluationType, userId, liveMessages, callback }: useHandleUserEvaluationParams) => {
      const { hasBeenEvaluatedBad, hasBeenEvaluatedGood } = liveMessages.chatDetails ?? {};
      const isEvaluationTypeGood = evaluationType === 'good';
      const isEvaluationTypeBad = evaluationType === 'bad';

      if (userId === liveMessages.chatDetails.chatOwnerId) {
        alertMessage(i18n.t('alertMessageYouCannotEvaluateYourSelf'));
      } else if (hasBeenEvaluatedGood || hasBeenEvaluatedBad) {
        alertMessage(i18n.t('alertMessageYouHaveAlreadyEvaluated'));
      } else {
        const { data } = (await updateUserEvaluations({
          chatId: liveMessages.chatDetails.id,
          createdByUserId: liveMessages.chatDetails.chatOwnerId,
          evaluationType,
        })) as any;

        callback({
          chatOwnerRecommendations: data.evaluations,
          hasBeenEvaluatedBad: isEvaluationTypeBad,
          hasBeenEvaluatedGood: isEvaluationTypeGood,
        });
      }
    },
    [i18n, updateUserEvaluations],
  );
};
