import { useMemo } from 'react';
import * as routeNames from '@lendticket/app/constants/routeNames';
import { useOnPressSocialSignin } from './useOnPressSocialSignin';
import { useCreateUser } from './useCreateUser';
import { useLoginUser } from './useLoginUser';
import { navigationRef } from '@lendticket/app/helpers/navigation/config';
import { useOnPressAppleSignin } from './useOnPressAppleSignin';
import { useI18n } from '@lendticket/app/hooks/i18n';

export const useLoginActions = ({ startLoading }: any) => {
  const i18n = useI18n();
  const onPressSocialSignin = useOnPressSocialSignin();
  const onPressAppleSignin = useOnPressAppleSignin();
  const createUser = useCreateUser();
  const loginUser = useLoginUser();

  return useMemo(
    () => ({
      create: {
        title: i18n.t('createAccount'),
        action: (data: any) => {
          startLoading({ isCancelable: true });
          createUser(data);
        },
      },
      login: {
        title: i18n.t('login'),
        action: (data: any) => {
          startLoading({ isCancelable: true });
          loginUser(data);
        },
      },
      forget: {
        title: i18n.t('forgotPassword'),
        action: () => navigationRef.current?.navigate(routeNames.FORGOT_PASSWORD),
      },
      facebook: {
        title: 'Facebook',
        action: (data: any) => {
          startLoading({ isCancelable: true });
          onPressSocialSignin({ method: data });
        },
      },
      google: {
        title: 'Google',
        action: (data: any) => {
          startLoading({ isCancelable: true });
          onPressSocialSignin({ method: data, isGoogle: true });
        },
      },
      apple: {
        title: 'Apple',
        action: (data: any) => {
          startLoading({ isCancelable: true });
          onPressAppleSignin({ method: data });
        },
      },
    }),
    [i18n, startLoading, createUser, loginUser, onPressSocialSignin, onPressAppleSignin],
  );
};
