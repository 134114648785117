import React, { useRef, useCallback, useContext, useEffect } from 'react';
import { TextInput } from 'react-native';
import { shallowEqual } from 'react-redux';
import { useTheme } from 'styled-components/native';
import { useForm } from 'react-hook-form';
import * as routeNames from '@lendticket/app/constants/routeNames';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import { BorderButton, Margin } from '@lendticket/ui/components/atoms';
import { UpdateUserPage } from '@lendticket/ui/components/templates';
import { usePasswordValidation } from '@lendticket/app/areas/Login/hooks/usePasswordValidation';
import { useUpdateUserSetAuthTokenAndRedirect } from '@lendticket/app/areas/Login/hooks/useUpdateUserSetAuthTokenAndRedirect';
import UpdateUserForm from './UpdateUserForm';
import api from '@lendticket/app/reducers/slices/api';
import { useAppSelector } from '@lendticket/app/hooks/redux';
import { userActions, userSelector } from '@lendticket/app/reducers/slices/user';
import { NotificationContext } from 'contexts';
import persisted from '@lendticket/app/helpers/storage/persisted';
import NotificationInformation from './NotificationInformation';
import { useAppDispatch } from '@lendticket/app/helpers/redux/store';
import { useI18n } from '@lendticket/app/hooks/i18n';

const UpdateUser: React.FC = () => {
  const inputRef = useRef<TextInput>(null);
  const { removeNotifications } = useContext(NotificationContext);
  const { windowDimensions, deviceSizes, settings } = useTheme();

  const i18n = useI18n();
  const user = useAppSelector(userSelector, shallowEqual);
  const isFocused = useIsFocused();
  const { control, handleSubmit } = useForm({ mode: 'onBlur' });
  const navigation = useNavigation();
  const dispatch = useAppDispatch();

  const { windowWidth } = windowDimensions;
  const { isMobile } = deviceSizes;
  const { CONSTANTS, SPACINGS } = settings;
  const { DESKTOP_TABLET_WIDTH } = CONSTANTS;
  const buttonWidth = isMobile
    ? windowWidth - SPACINGS.xl * 2
    : DESKTOP_TABLET_WIDTH - SPACINGS.xxxxl * 2;

  const passwordValidation = usePasswordValidation();
  const updateUserSetAuthTokenAndRedirect = useUpdateUserSetAuthTokenAndRedirect();

  const [deleteUserByUserId] = api.useDeleteUserByUserIdMutation();
  const [updateUserByUserId] = api.useUpdateUserByUserIdMutation();

  const updateUser = useCallback(
    async (data: any) => {
      let isPasswordValid: any = true;
      const hasData = Object.values(data).filter(item => item).length > 0;

      if (data?.password) {
        isPasswordValid = passwordValidation(data);
      }

      if (hasData && isPasswordValid) {
        const { data: updatedUserToken }: any = await updateUserByUserId({
          userId: user?.id,
          data,
        });

        if (updatedUserToken) {
          updateUserSetAuthTokenAndRedirect(updatedUserToken, false);
        }
      }
    },
    [user, passwordValidation, updateUserByUserId, updateUserSetAuthTokenAndRedirect],
  );

  const deleteUser = useCallback(async () => {
    if (user?.id) {
      const result: any = await deleteUserByUserId(user.id);
      const notifications = await persisted.getItem('notifications');

      if (result?.ticketIds) {
        result.ticketIds.forEach((ticketId: string) => {
          if (notifications?.ticketId === ticketId) {
            removeNotifications(ticketId);
          }
        });
      }

      await persisted.removeItem('authToken');
      dispatch(userActions.resetUserInfo());
      navigation.reset({ index: 0, routes: [{ name: routeNames.LOGIN }] });
    }
  }, [deleteUserByUserId, dispatch, navigation, removeNotifications, user]);

  useEffect(() => {
    if (isFocused) {
      inputRef?.current?.clear();
    }
  }, [isFocused]);

  return (
    <UpdateUserPage
      teaserTitle={i18n.t('updateYourUserInformationTitle')}
      teaserText={i18n.t('keepYourInformationUpToDate')}
      footerText={i18n.t('deleteUser')}
      footerComponent={
        <Margin marginBottom="md">
          <BorderButton
            style={{ width: buttonWidth }}
            title="Opdater bruger"
            onPress={handleSubmit(updateUser)}
          />
          <Margin marginTop="md" />
          <BorderButton
            style={{ width: buttonWidth }}
            title={i18n.t('deleteUser')}
            onPress={deleteUser}
          />
        </Margin>
      }
      notificationComponent={<NotificationInformation />}
      inputFieldsComponent={
        <UpdateUserForm inputRef={inputRef} width={buttonWidth} control={control} />
      }
    />
  );
};

export default UpdateUser;
