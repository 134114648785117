import { useTheme } from 'styled-components/native';
import React, { Fragment, useCallback } from 'react';
import { Platform } from 'react-native';
import moment from 'moment';
import { Calendar as RNCalendars } from 'react-native-calendars';
import { Icon, LabelAndDescription } from '@lendticket/ui/components/molecules';
import { Wrapper, Margin, Text, Button } from '@lendticket/ui/components/atoms';
import { getTextStyle } from '@lendticket/ui/helpers';
import SelectedPeriods from './SelectedPeriods';
import { useI18n } from '@lendticket/app/hooks/i18n';

const isWeb = Platform.OS === 'web';
const ARROW_DIMENSIONS = 20;
const BORDER_RADIUS = 7;
const BORDER_WIDTH = 3;
const TEXT_DAY_SIZE = 14;
const DEFAULT_STATE = {
  markedDates: {},
  isStartDatePicked: false,
  isEndDatePicked: false,
  startDate: '',
};

interface CalenderProps {
  selectedPeriods: any[];
  isLendingOutSelected: boolean;
  datePickerDates: any;
  setDatePickerDates: (updateParams: any) => void;
}

const Calendar: React.FC<CalenderProps> = ({
  selectedPeriods,
  isLendingOutSelected,
  datePickerDates,
  setDatePickerDates,
}) => {
  const i18n = useI18n();
  const theme = useTheme();
  const { settings, deviceSizes } = theme;
  const { isMobile } = deviceSizes;
  const { COLORS, SPACINGS } = settings;
  const fontType = isMobile && !isWeb ? 'Hubot-Sans-SemiBoldSemi' : 'Hubot-Sans';

  const onDayPress = useCallback(
    (day: Record<string, any>) => {
      if (!datePickerDates.isStartDatePicked) {
        let markedDates = {} as any;
        markedDates[day.dateString] = {
          startingDay: true,
          endingDay: true,
          color: COLORS.WHITE,
          textColor: COLORS.BACKGROUND_PRIMARY,
        };

        const updateParams = {
          markedDates: markedDates,
          isStartDatePicked: true,
          isEndDatePicked: false,
          startDate: day.dateString,
        };

        setDatePickerDates({
          ticket: isLendingOutSelected ? updateParams : datePickerDates.ticket,
          profile: isLendingOutSelected ? datePickerDates.profile : updateParams,
        });
      } else {
        let markedDates = datePickerDates.markedDates;
        let startDate = moment(datePickerDates.startDate);
        let endDate = moment(day.dateString);
        let range = endDate.diff(startDate, 'days');
        if (range > 0) {
          for (let i = 1; i <= range; i++) {
            let tempDate = startDate.add(1, 'day');
            // @ts-expect-error bug in moment
            tempDate = moment(tempDate).format('YYYY-MM-DD');
            // @ts-expect-error bug in moment
            markedDates[datePickerDates.startDate] = {
              ...markedDates[datePickerDates.startDate],
              endingDay: false,
            };

            if (i < range) {
              // @ts-expect-error bug in moment
              markedDates[tempDate] = { color: COLORS.WHITE, textColor: COLORS.BACKGROUND_PRIMARY };
            } else {
              // @ts-expect-error bug in moment
              markedDates[tempDate] = {
                endingDay: true,
                color: COLORS.WHITE,
                textColor: COLORS.BACKGROUND_PRIMARY,
              };
            }
          }

          const updateParams = {
            markedDates: markedDates,
            isStartDatePicked: false,
            isEndDatePicked: true,
            startDate: '',
          };

          setDatePickerDates({
            ticket: isLendingOutSelected ? updateParams : datePickerDates.ticket,
            profile: isLendingOutSelected ? datePickerDates.profile : updateParams,
          });
        } else {
          // eslint-disable-next-line no-alert
          alert(i18n.t('calendarPleaseSelectAFutureDate'));
        }
      }
    },
    [
      COLORS.BACKGROUND_PRIMARY,
      COLORS.WHITE,
      i18n,
      datePickerDates,
      isLendingOutSelected,
      setDatePickerDates,
    ],
  );

  return (
    <Fragment>
      {isWeb && (
        <LabelAndDescription
          title={i18n.t('calendarSelectOneOrMultipleDates')}
          description={i18n.t('calendarDescription')}
        />
      )}
      {selectedPeriods?.map((items: any, i: number) => (
        <SelectedPeriods key={`${items.periodStart}-${i}`} i={i} {...items} />
      ))}
      <Margin marginTop="md" />
      <Wrapper
        h={50}
        fd="row"
        bc={COLORS.BACKGROUND_LIGHT_ORANGE}
        style={{
          paddingLeft: 16,
          paddingRight: 16,
          borderTopWidth: BORDER_WIDTH,
          borderBottomWidth: 0,
          borderLeftWidth: BORDER_WIDTH,
          borderRightWidth: BORDER_WIDTH,
          borderColor: COLORS.BACKGROUND_LIGHT_ORANGE,
          borderTopRightRadius: BORDER_RADIUS,
          borderTopLeftRadius: BORDER_RADIUS,
        }}
      >
        <Wrapper f={1} ai="flex-start">
          <Button
            onPress={() =>
              setDatePickerDates({
                ticket: isLendingOutSelected ? DEFAULT_STATE : datePickerDates.ticket,
                profile: isLendingOutSelected ? datePickerDates.profile : DEFAULT_STATE,
              })
            }
          >
            <Text
              fontType={isMobile ? 'body' : 'h4'}
              style={{ fontWeight: 'bold', color: COLORS.WHITE }}
            >
              {i18n.t('reset')}
            </Text>
          </Button>
        </Wrapper>
      </Wrapper>
      <RNCalendars
        renderArrow={(direction: string) => (
          <Wrapper
            mb={SPACINGS.md}
            style={{
              transform: [{ rotate: `${direction === 'right' ? 180 : 0}deg` }],
            }}
          >
            <Icon
              name="arrowLeft"
              fill={COLORS.WHITE}
              height={ARROW_DIMENSIONS}
              width={ARROW_DIMENSIONS}
            />
          </Wrapper>
        )}
        style={{
          borderLeftWidth: BORDER_WIDTH,
          borderRightWidth: BORDER_WIDTH,
          borderBottomWidth: BORDER_WIDTH,
          borderBottomLeftRadius: BORDER_RADIUS,
          borderBottomRightRadius: BORDER_RADIUS,
          borderColor: COLORS.BACKGROUND_LIGHT_ORANGE,
          paddingBottom: SPACINGS.md,
        }}
        theme={{
          backgroundColor: 'transparent',
          calendarBackground: 'transparent',
          textSectionTitleColor: COLORS.WHITE,
          textSectionTitleDisabledColor: '#d9e1e8',
          selectedDayBackgroundColor: COLORS.WHITE,
          selectedDayTextColor: COLORS.TEXT_PURPLE,
          todayTextColor: COLORS.WHITE,
          dayTextColor: COLORS.WHITE,
          textDisabledColor: COLORS.BACKGROUND_LIGHT_ORANGE,
          dotColor: COLORS.WHITE,
          selectedDotColor: 'transparent',
          arrowColor: 'orange',
          disabledArrowColor: '#d9e1e8',
          monthTextColor: COLORS.WHITE,
          indicatorColor: COLORS.WHITE,
          textDayFontFamily: fontType,
          textMonthFontFamily: fontType,
          textDayHeaderFontFamily: fontType,
          textDayFontWeight: 'bold',
          textDayHeaderFontWeight: 'bold',
          textDayFontSize: TEXT_DAY_SIZE,
          textDayHeaderFontSize: TEXT_DAY_SIZE,
          // @ts-expect-error bug react-native-calendars
          'stylesheet.calendar.header': {
            monthText: {
              ...getTextStyle({ fontType: isMobile && !isWeb ? 'body' : 'h4', theme }),
              color: COLORS.WHITE,
              marginBottom: SPACINGS.md,
            },
          },
        }}
        minDate={Date()}
        onDayPress={onDayPress}
        markingType={'period'}
        markedDates={datePickerDates?.markedDates}
        hideExtraDays={true}
      />
    </Fragment>
  );
};

export default Calendar;
