import React, { useState, useEffect } from 'react';
import { Linking } from 'react-native';
import { SunsetProviderWrapper } from '@lendticket/app/contexts';
import { isWeb, isIOS } from '@lendticket/app/helpers/platform';
import { useHandleSunsetAndLockdown } from './useHandleSunsetAndLockdown';
import { NativeAlert } from '@lendticket/ui/components/organisms';
import { useI18n } from '@lendticket/app/hooks/i18n';
import { alertMessage } from '@lendticket/app/helpers/alertMessage';
import { useHeadlessTask } from '@lendticket/ui/hooks';

const APP_STORE_SCHEMA = 'itms-apps://apps.apple.com/se/app/lend-ticket/id6445863215';
const PLAY_STORE_LINK = 'https://play.google.com/store/apps/details?id=com.lendticket';

interface SunsetProviderProps {
  children: React.ReactNode;
}

export const DEFAULT_SUNSET_INFORMATION_STATE = {
  isVisible: false,
  isSunsetAndLockdownHandled: false,
  isAppLocked: false,
};

const SunsetProvider: React.FC<SunsetProviderProps> = ({ children }) => {
  const i18n = useI18n();
  const [isSunsetAndLockdownHandled, setIsSunsetAndLockdownHandled] = useState(false);
  const [sunsetInformationState, setSunsetInformationState] = useState(
    DEFAULT_SUNSET_INFORMATION_STATE,
  );

  const handleSunsetAndlockdown = useHandleSunsetAndLockdown();

  useEffect(() => {
    if (!isWeb) {
      handleSunsetAndlockdown({
        isSunsetAndLockdownHandled,
        setIsSunsetAndLockdownHandled,
        setSunsetInformationState,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useHeadlessTask({
    handleHeadlessTask: () => {
      setIsSunsetAndLockdownHandled(false);
      handleSunsetAndlockdown({
        isSunsetAndLockdownHandled,
        setIsSunsetAndLockdownHandled,
        setSunsetInformationState,
      });
    },
  });

  return (
    <SunsetProviderWrapper
      value={{
        handleSunsetAndlockdown: () =>
          handleSunsetAndlockdown({
            isSunsetAndLockdownHandled,
            setIsSunsetAndLockdownHandled,
            setSunsetInformationState,
          }),
      }}
    >
      {children}
      <NativeAlert
        title={
          sunsetInformationState.isAppLocked
            ? i18n.t('appLockdownTitle')
            : i18n.t('sunsetTimeForUpdate')
        }
        message={
          sunsetInformationState.isAppLocked
            ? i18n.t('appLockdownDescription')
            : i18n.t('sunsetDescription')
        }
        visible={sunsetInformationState.isVisible}
        onConfirmButtonText={i18n.t(isIOS ? 'sunsetButtonAppStore' : 'sunsetButtonPlayStore')}
        onClose={
          sunsetInformationState.isAppLocked
            ? undefined
            : () => setSunsetInformationState({ ...sunsetInformationState, isVisible: false })
        }
        onCloseButtonText={i18n.t('ignore')}
        onConfirm={() => {
          if (!sunsetInformationState.isAppLocked) {
            setSunsetInformationState({ ...sunsetInformationState, isVisible: false });
          }

          const playStoreSchemaLink = isIOS ? APP_STORE_SCHEMA : PLAY_STORE_LINK;
          Linking.canOpenURL(playStoreSchemaLink).then(
            supported => {
              if (!isIOS) {
                /* NOTE: Android has some wierd bug with making
              the link supported as false even though it is accessible */
                Linking.openURL(playStoreSchemaLink);
              } else if (supported && isIOS) {
                Linking.openURL(playStoreSchemaLink);
              } else {
                alertMessage(
                  i18n.t(isIOS ? 'linkNotSupportedAppStore' : 'linkNotSupportedPlayStore'),
                );
              }
            },
            err => alertMessage(err.message),
          );
        }}
      />
    </SunsetProviderWrapper>
  );
};

export default SunsetProvider;
