import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/query/react';
import type { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import type { TagTypes } from '.';
import { navigationRef } from '@lendticket/app/helpers/navigation/config';

export const chats = (
  build: EndpointBuilder<
    BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, FetchBaseQueryMeta>,
    TagTypes,
    'api'
  >,
) => ({
  getChatsByUserId: build.query<any, any>({
    query: userId => `/chats/user/${userId}`,
  }),
  getChatByFavoriteIdAndUserId: build.query<any, any>({
    query: (searchParams: any) =>
      `/chats/favorite/${searchParams.favoriteId}/user/${searchParams.userId}`,
  }),
  getChatByProfileIdAndUserId: build.query<any, any>({
    query: (searchParams: any) =>
      `/chats/profile/${searchParams.profileId}/user/${searchParams.userId}`,
  }),
  getChatByTicketIdAndUserId: build.query<any, any>({
    query: (searchParams: any) =>
      `/chats/ticket/${searchParams.ticketId}/user/${searchParams.userId}`,
  }),
  getChatByChatIdAndUserId: build.query<any, any>({
    query: searchParams => `/chats/${searchParams.chatId}/user/${searchParams.userId}`,
  }),
  getChatsByChatId: build.query<any, void>({
    query: chatId => `/chats/${chatId}`,
  }),
  updateChatByChatId: build.mutation<boolean, any>({
    query: (data: any) => ({
      url: `/chats/${data.chatId}`,
      method: 'PUT',
      body: data,
    }),
  }),
  createChat: build.mutation<boolean, any>({
    query: (data: any) => ({
      url: '/chats/create',
      method: 'POST',
      body: data,
    }),
  }),
  deleteChatByProfileId: build.mutation<boolean, any>({
    query: (profileId: string) => ({
      url: `/chats/profile/${profileId}`,
      method: 'DELETE',
    }),
  }),
  deleteChatByTicketId: build.mutation<boolean, any>({
    query: (ticketId: string) => ({
      url: `/chats/${ticketId}`,
      method: 'DELETE',
    }),
  }),
  deleteChatByChatId: build.mutation<boolean, any>({
    query: (chatId: string) => ({
      url: `/chats/${chatId}`,
      method: 'DELETE',
    }),
  }),
  sendChatNotification: build.mutation<any, any>({
    query: (data: any) => ({
      url: '/firebase/notification',
      method: 'POST',
      body: data,
    }),
  }),
});

export const favoriteSelector = (state: any) => {
  const user = state.root.user.info;
  const { queries } = state.api;
  const currentRoute = navigationRef.current?.getCurrentRoute();
  const { ticketId, profileId, favoriteId, chatId, userId }: any = currentRoute?.params ?? {};

  const getLendTicketByTicketIdIndex = Object.keys(queries).findIndex(key =>
    key.includes(`getLendTicketByTicketId("${ticketId}"`),
  );

  const getChatByChatIdAndUserIdIndex = Object.keys(queries).findIndex(key =>
    key.includes(`getChatByChatIdAndUserId({"chatId":"${chatId}","userId":"${userId}"})`),
  );

  const getLendProfileByProfileIdIndex = Object.keys(queries).findIndex(key =>
    key.includes(`getLendProfileByProfileId("${profileId}")`),
  );

  if (user?.favorites?.includes(favoriteId)) {
    return {
      isFavorite: true,
      favoriteUserId: favoriteId,
    };
  } else if (
    getChatByChatIdAndUserIdIndex > 0 ||
    getLendTicketByTicketIdIndex > 0 ||
    getLendProfileByProfileIdIndex > 0
  ) {
    const { data: getChatByChatIdAndUserIdData } =
      queries[Object.keys(queries)[getChatByChatIdAndUserIdIndex]] ?? {};
    const { data: getLendTicketByTicketIdData } =
      queries[Object.keys(queries)[getLendTicketByTicketIdIndex]] ?? {};
    const { data: getLendProfileByProfileIdData } =
      queries[Object.keys(queries)[getLendProfileByProfileIdIndex]] ?? {};

    if (getLendTicketByTicketIdData?.status === 200) {
      const { ticket } = getLendTicketByTicketIdData ?? {};

      return {
        isFavorite: user?.favorites?.includes(ticket.createdByUserId),
        favoriteUserId: ticket.createdByUserId,
      };
    } else if (getChatByChatIdAndUserIdData?.status === 200) {
      const { chat } = getChatByChatIdAndUserIdData ?? {};
      const isFavorite = user?.favorites?.some((id: string) =>
        chat?.chatParticipantIds?.some((participantId: string) => participantId === id),
      );

      return {
        isFavorite,
        favoriteUserId: chat?.chatParticipantIds?.filter((id: string) => id !== user?.id)[0],
      };
    } else if (getLendProfileByProfileIdData?.status === 200) {
      const { profile } = getLendProfileByProfileIdData ?? {};

      return {
        isFavorite: user?.favorites?.includes(profile.createdByUserId),
        favoriteUserId: profile.createdByUserId,
      };
    }
  }
};
