import React from 'react';
import { createRoot } from 'react-dom/client';
import { enableLegacyWebImplementation } from 'react-native-gesture-handler';
import App from './App';

enableLegacyWebImplementation(true);

const container = document.getElementById('root');
const root = createRoot(container as HTMLElement);
root.render(<App />);
