import i18next, { t, TOptionsBase } from 'i18next';
import { initReactI18next } from 'react-i18next';
import resources from '@lendticket/app/assets/i18n/resources';
import persisted from '@lendticket/app/helpers/storage/persisted';

export const DEFAULT_NAMESPACE = 'common';
const NAME_SPACES = ['common'];
const FALLBACK = 'se';

const languageDetector: any = {
  type: 'languageDetector',
  async: true,
  detect: (callback: any) => {
    persisted.getItem('language').then((language: string) => {
      callback(language);
    });
  },
  init: (): any => {},
  cacheUserLanguage: (): any => {},
};

export const i18n = {
  init: (): Promise<unknown> =>
    new Promise((resolve, reject) => {
      /* eslint-disable-next-line import/no-named-as-default-member */
      i18next
        .use(initReactI18next)
        .use(languageDetector)
        .init(
          {
            compatibilityJSON: 'v3',
            lng: i18next.language,
            debug: false,
            saveMissing: false,
            fallbackLng: FALLBACK,
            resources,
            ns: NAME_SPACES,
            defaultNS: DEFAULT_NAMESPACE,
            keySeparator: false,
            nsSeparator: '|',
            returnEmptyString: false,
            react: {
              useSuspense: true,
            },
          },
          error => {
            if (error) {
              return reject(error);
            }
            return resolve(true);
          },
        );
    }),

  get locale() {
    return i18next.language;
  },

  t: (key: string, options: TOptionsBase & object & { defaultValue: string }): string => {
    return t(key, options);
  },

  addResourceBundle: (lng: string, namespace: string, keyValues: string): any =>
    i18next.addResourceBundle(lng, namespace, keyValues, true, true),
};

i18next.on('initialized', () => console.log('[TC] Translation config initialized successfully'));
i18next.on('languageChanged', lng => console.log(`[TC] Language changed to ${lng}`));
i18next.on('missingKey', (lngs, namespace, _key, res) => {
  console.log('missing key lngs:', lngs, 'namespace:', namespace, 'res:', res);
});
