import React, { useMemo, useContext } from 'react';
import { Platform, TouchableOpacity, Keyboard } from 'react-native';
import { useRoute } from '@react-navigation/native';
import { useTheme } from 'styled-components/native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { Wrapper, LeaveAReview } from '@lendticket/ui/components/atoms';
import { PageDimensionsContext } from '@lendticket/ui/containers/PageDimensionsProvider';
import { KeyboardContext } from '@lendticket/ui/containers/KeyboardProvider';
import {
  WEB_MOBILE_HEIGHT,
  WEB_DESKTOP_TABLET_HEIGHT,
} from '@lendticket/ui/components/organisms/Header';

const isAndroid = Platform.OS === 'android';
const isWeb = Platform.OS === 'web';
const isIOS = Platform.OS === 'ios';

const HEADER_HEIGHT_NATIVE = 65;
const TABBAR_HEIGHT = 100;

interface FullWrapperHeightProps {
  hasBottomTabBar?: boolean;
  hasHeader?: boolean;
  isModal?: boolean;
  footer?: React.ReactNode;
  ignoreHeaderHeight?: boolean;
  children: React.ReactNode;
}

const FullWrapperHeight: React.FC<FullWrapperHeightProps> = ({
  hasBottomTabBar = true,
  ignoreHeaderHeight = false,
  hasHeader = true,
  isModal = false,
  footer,
  children,
}) => {
  const { contentHeight, headerHeight, footerHeight } = useContext(PageDimensionsContext);
  const { isKeyboardVisible } = useContext(KeyboardContext);
  const { windowDimensions, deviceSizes } = useTheme();
  const { isMobile, isTablet } = deviceSizes;
  const { bottom } = useSafeAreaInsets();
  const { name } = useRoute();
  const isRouteOnboarding = name === 'onboarding';
  const isRouteChat = name === 'chat';
  const isRouteCreate = name === 'create';
  const isRouteHome = name === 'home';
  const isRouteLogin = name === 'login';
  const styleNative =
    isWeb || isRouteChat || (isAndroid && isTablet)
      ? {}
      : {
          display: 'flex',
          height: '100%',
        };

  const hasBorderWrapper = !isModal && !isRouteHome && !isRouteCreate && !isRouteLogin;
  const derivedHeaderHeight = useMemo(() => {
    if (isWeb || isTablet) {
      return isMobile ? WEB_MOBILE_HEIGHT : WEB_DESKTOP_TABLET_HEIGHT;
    }

    return HEADER_HEIGHT_NATIVE;
  }, [isMobile, isTablet]);

  return (
    <Wrapper
      fd="column"
      p="relative"
      style={{ ...styleNative, height: isWeb ? windowDimensions.windowHeight : '100%', zIndex: 9 }}
    >
      {hasHeader && (
        <Wrapper
          onLayout={e => {
            if (isWeb && headerHeight) {
              headerHeight.value = e.nativeEvent.layout.height;
            }
          }}
          h={ignoreHeaderHeight ? 0 : derivedHeaderHeight}
        />
      )}
      <Wrapper f={1}>
        <TouchableOpacity
          onLayout={e => {
            if (isWeb && contentHeight) {
              contentHeight.value = e.nativeEvent.layout.height;
            }
          }}
          disabled={!isWeb && !isKeyboardVisible}
          onPress={() => {
            if (!isWeb && isKeyboardVisible) {
              Keyboard.dismiss();
            }
          }}
          activeOpacity={1}
          style={{
            cursor: 'default',
            flex: 1,
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {children}
        </TouchableOpacity>
      </Wrapper>
      {hasBorderWrapper && <LeaveAReview />}
      {footer && (
        <Wrapper
          jc={isRouteOnboarding ? 'center' : 'flex-end'}
          style={{ display: 'flex' }}
          onLayout={e => {
            if (isWeb && footerHeight) {
              footerHeight.value = e.nativeEvent.layout.height;
            }
          }}
        >
          {footer}
        </Wrapper>
      )}
      {hasBottomTabBar && (
        <Wrapper h={TABBAR_HEIGHT - (bottom ? bottom / 2 : 0)} style={{ zIndex: -1 }} />
      )}
    </Wrapper>
  );
};

export default FullWrapperHeight;
