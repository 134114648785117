import { useAppDispatch } from '@lendticket/app/hooks/redux';
import React, { useCallback } from 'react';
import { useNavigation } from '@react-navigation/native';
import { showMessage } from 'react-native-flash-message';
import { v4 as uuidv4 } from 'uuid';
import * as routeNames from '@lendticket/app/constants/routeNames';
import api from '@lendticket/app/reducers/slices/api';
import { alertMessage } from '@lendticket/app/helpers/alertMessage';
import { profileActions } from '@lendticket/app/reducers/slices/profiles';
import { useI18n } from '@lendticket/app/hooks/i18n';

interface UseCreateTicketsParams {
  selectedPeriods: any[];
  data: any;
  latestUser: Record<string, any>;
  ticketTypesAndPayment: any;
  multipleDataSets: any[];
  isAllOfSkaeneSelected: boolean;
  reduceString: (val: string) => void;
  setHasCreations: React.Dispatch<React.SetStateAction<undefined | boolean>>;
}

export const useCreateProfile = () => {
  const i18n = useI18n();
  const dispatch = useAppDispatch();
  const [createLendProfile] = api.useCreateLendProfileMutation();
  const navigation = useNavigation();

  return useCallback(
    ({
      selectedPeriods,
      data,
      latestUser,
      multipleDataSets,
      ticketTypesAndPayment,
      isAllOfSkaeneSelected,
      reduceString,
      setHasCreations,
    }: UseCreateTicketsParams) => {
      const profile = selectedPeriods.map((period: object, i: number) => ({
        ...(multipleDataSets?.length > 0 ? multipleDataSets[i] : data),
        ...period,
        ...ticketTypesAndPayment,
        isLendProfile: true,
        from: isAllOfSkaeneSelected
          ? undefined
          : reduceString(multipleDataSets?.length > 0 ? multipleDataSets[i].from : data.from),
        to: isAllOfSkaeneSelected
          ? undefined
          : reduceString(multipleDataSets?.length > 0 ? multipleDataSets[i].to : data.to),
        name: reduceString(latestUser.name),
        id: uuidv4(),
        createdByUserId: latestUser.id,
        createdAt: new Date().getTime(),
        isIncludingAllOfSkaene: isAllOfSkaeneSelected,
        isLoggedInWithFacebook: latestUser?.socialUserIdFacebook,
        isLoggedInWithGoogle: latestUser?.socialUserIdGoogle,
        isLoggedInWithApple: latestUser?.socialUserIdApple,
        hasHighScore: latestUser?.userEvaluations?.good - latestUser?.userEvaluations.bad >= 5,
        initials: latestUser?.name?.slice(0, 2).toUpperCase(),
        img: latestUser.img && !latestUser?.socialUserIdFacebook ? latestUser.img : undefined,
      }));

      createLendProfile(profile)
        .then((res: any) => {
          setHasCreations(true);

          const { profiles: dataProfiles } = res?.data ?? {};

          if (dataProfiles.length > 0) {
            dispatch(
              profileActions.addProfiles({
                profiles: dataProfiles,
                profileIds: dataProfiles.map(({ id }: { id: string }) => id),
              }),
            );

            navigation.navigate(routeNames.FIND_TICKETS_AND_PROFILES, {
              ignoreupdate: true,
              isprofile: true,
            });
            showMessage({
              message: 'Succes',
              description: i18n.t('greatSuccess'),
              style: { backgroundColor: '#235347' },
              icon: 'success',
              type: 'success',
            });
          }
        })
        .catch(error => {
          alertMessage(error);
        });
    },
    [navigation, i18n, createLendProfile, dispatch],
  );
};
