import React from 'react';
import { Platform } from 'react-native';
import styled, { useTheme } from 'styled-components/native';
import { SafeAreaView } from 'react-native-safe-area-context';
import {
  FullWrapperHeight,
  Gradient,
  SendMessageInputField,
  NoContent,
  DefaultProfileImage,
  AreYouSatisfiedWithUser,
} from '@lendticket/ui/components/molecules';
import { Text, Margin, Wrapper, MarginColumn } from '@lendticket/ui/components/atoms';
import { KeyboardAvoid, ChatMessages, Appearance } from '@lendticket/ui/components/organisms';
import Animated, {
  useAnimatedReaction,
  useAnimatedStyle,
  useSharedValue,
  withSpring,
} from 'react-native-reanimated';
import { DEFAULT_SPRING_CONFIG } from '@lendticket/ui/constants';
import { useI18n } from '../../../../app/src/hooks/i18n';

const isWeb = Platform.OS === 'web';
const isIOS = Platform.OS === 'ios';
const BORDER_BOTTOM_RADIUS = 7;
const IMAGE_DIMENSIONS_WEB = 100;
const IMAGE_DIMENSIONS_MOBILE = 80;

export interface MessagesParams {
  id: string;
  text: string;
  user: string;
  time: string;
}

interface ChatProps {
  chat: any;
  userId?: string;
  userName?: string;
  messages: MessagesParams[];
  newMessageIds: string[];
  isChatDead: boolean;
  isChatCreated: boolean;
  isChatDetailsVisible: boolean;
  handleUserEvaluations: (evaluationType: string) => void;
  handleNewMessage: (message: string) => void;
}

const ImageWrapper = styled.Image`
  border-radius: 999px;
  height: ${({ theme }) =>
    theme.deviceSizes.isMobile ? IMAGE_DIMENSIONS_MOBILE : IMAGE_DIMENSIONS_WEB}px;
  width: ${({ theme }) =>
    theme.deviceSizes.isMobile ? IMAGE_DIMENSIONS_MOBILE : IMAGE_DIMENSIONS_WEB}px;
`;

const WebScrollView = ({
  isChatDetailsVisible,
  children,
}: {
  isChatDetailsVisible: boolean;
  children: React.ReactNode;
}) =>
  Platform.select({
    default: <>{children}</>,
    web: (
      <Animated.ScrollView
        style={{
          width: '100%',
          height: '100%',
          position: isChatDetailsVisible ? 'relative' : 'absolute',
        }}
      >
        {children}
      </Animated.ScrollView>
    ),
  });

const ChatPage: React.FC<ChatProps> = ({
  chat,
  userId,
  userName,
  newMessageIds,
  isChatDetailsVisible,
  isChatDead,
  isChatCreated,
  messages,
  handleNewMessage,
  handleUserEvaluations,
}) => {
  const i18n = useI18n();
  const { deviceSizes, settings } = useTheme();
  const { isMobile } = deviceSizes;
  const { COLORS, CONSTANTS, SPACINGS, SHADOWS } = settings;
  const { DESKTOP_TABLET_WIDTH } = CONSTANTS;
  const shadow = isMobile ? SHADOWS.BOX_SHADOW_BOTTOM_SECONDARY : {};
  const isUserEvaluationsVisible = useSharedValue<boolean>(false);
  const height = useSharedValue(0);
  const translateY = useSharedValue(0);

  useAnimatedReaction(
    () => isChatDetailsVisible,
    res => {
      translateY.value = withSpring(res ? 0 : -height.value, DEFAULT_SPRING_CONFIG);
    },
    [isChatDetailsVisible, height],
  );

  const animatedStyle = useAnimatedStyle(() => ({
    transform: [{ translateY: translateY.value }],
    position: 'relative',
    width: '100%',
  }));

  return (
    <SafeAreaView>
      <Gradient />
      <FullWrapperHeight
        hasBottomTabBar={false}
        ignoreHeaderHeight={isIOS}
        isModal
        footer={
          <KeyboardAvoid>
            <SendMessageInputField isChatDead={isChatDead} onPress={handleNewMessage} />
          </KeyboardAvoid>
        }
      >
        <Wrapper f={1} w={isMobile ? undefined : DESKTOP_TABLET_WIDTH}>
          {!isChatDead && (
            <Animated.View
              style={animatedStyle}
              onLayout={e => (height.value = e.nativeEvent.layout.height)}
            >
              <Wrapper
                fd="column"
                bc={isMobile ? COLORS.BACKGROUND_LIGHT : 'transparent'}
                style={{
                  paddingBottom: isIOS && !isChatDetailsVisible ? 16 : 0,
                  borderBottomLeftRadius: BORDER_BOTTOM_RADIUS,
                  borderBottomRightRadius: BORDER_BOTTOM_RADIUS,
                  ...shadow,
                }}
              >
                {Object.values(chat ?? {}).length > 0 && (
                  <Appearance isFlex={false}>
                    <Wrapper h={130} jc="center">
                      <Margin marginBottom="md">
                        <Wrapper fd="row">
                          {chat?.img ? (
                            <ImageWrapper source={{ uri: chat.img }} />
                          ) : (
                            <DefaultProfileImage
                              height={isMobile ? 80 : 100}
                              width={isMobile ? 80 : 100}
                              text={chat.initials?.slice(0, 2).toUpperCase()}
                              backgroundColor={COLORS.TEXT_LIGHT_PURPLE}
                            />
                          )}
                          {chat?.isFavorite && (
                            <>
                              <Margin marginLeft="s" />
                              {chat?.favoriteImg ? (
                                <ImageWrapper source={{ uri: chat.favoriteImg }} />
                              ) : (
                                <DefaultProfileImage
                                  height={isMobile ? 80 : 100}
                                  width={isMobile ? 80 : 100}
                                  text={chat.favoriteInitials?.slice(0, 2).toUpperCase()}
                                  backgroundColor={COLORS.TEXT_LIGHT_PURPLE}
                                />
                              )}
                            </>
                          )}
                        </Wrapper>
                      </Margin>
                    </Wrapper>
                    {!chat?.isFavorite && (
                      <AreYouSatisfiedWithUser
                        handleUserEvaluations={handleUserEvaluations}
                        isChatCreated={isChatCreated}
                        isUserEvaluationsVisible={isUserEvaluationsVisible}
                        ratingGood={chat?.chatOwnerRecommendations?.good}
                        ratingBad={chat?.chatOwnerRecommendations?.bad}
                      />
                    )}
                    <Margin marginTop="s" marginBottom="xl">
                      {chat?.isFavorite && (
                        <Text
                          fontType={isMobile ? 'body' : 'h4'}
                          style={{
                            color: isMobile ? COLORS.TEXT_LIGHT_PURPLE : 'rgba(255,255,255,0.5)',
                            textAlign: 'center',
                          }}
                        >
                          {i18n.t('chatFavoriteDescription')}
                        </Text>
                      )}
                      {chat?.periodStart && chat?.periodEnd && (
                        <Text
                          fontType={isMobile ? 'body' : 'h4'}
                          style={{
                            color: isMobile ? COLORS.TEXT_LIGHT_PURPLE : 'rgba(255,255,255,0.5)',
                            textAlign: 'center',
                          }}
                        >
                          {chat?.periodStart} - {chat?.periodEnd}
                        </Text>
                      )}
                      {(chat?.isIncludingAllOfSkaene || (chat?.from && chat?.to)) && (
                        <Text
                          fontType={isMobile ? 'h5' : 'h3'}
                          style={{
                            color: isMobile ? COLORS.TEXT_LIGHT_PURPLE : '#FFF',
                            textAlign: 'center',
                            ...(isIOS ? { lineHeight: 30 } : {}),
                          }}
                        >
                          {chat?.isIncludingAllOfSkaene
                            ? i18n.t('allOfSkaene')
                            : `${chat?.from} til \n${chat?.to}`}
                        </Text>
                      )}
                    </Margin>
                  </Appearance>
                )}
              </Wrapper>
            </Animated.View>
          )}
          <WebScrollView isChatDetailsVisible={isChatDetailsVisible}>
            <Animated.View style={{ flex: isChatDetailsVisible ? 1 : 0, width: '100%' }}>
              <Wrapper fd="row" ai="flex-start" mb={SPACINGS.md}>
                <MarginColumn />
                <Wrapper f={1} fd="column" ai="flex-start" style={{ alignSelf: 'stretch' }}>
                  <Animated.View style={isWeb ? { width: '100%' } : animatedStyle}>
                    {isChatDead ? (
                      <NoContent text={i18n.t('conversationIsNoLongerAvailable')} />
                    ) : (
                      <ChatMessages
                        messages={messages}
                        newMessageIds={newMessageIds}
                        userId={userId}
                        userName={userName}
                      />
                    )}
                  </Animated.View>
                </Wrapper>
                <MarginColumn />
              </Wrapper>
            </Animated.View>
          </WebScrollView>
        </Wrapper>
      </FullWrapperHeight>
    </SafeAreaView>
  );
};

export default ChatPage;
