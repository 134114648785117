import React, { useState, useRef, useCallback } from 'react';
import { Platform } from 'react-native';
import { useTheme } from 'styled-components/native';
import { Controller, FieldValues, Control } from 'react-hook-form';
import { Wrapper, Margin } from '@lendticket/ui/components/atoms';
import { LabelAndDescription, InputField } from '@lendticket/ui/components/molecules';
import Dropdown from './Dropdown';
import { useI18n } from '@lendticket/app/hooks/i18n';
import SelectAllOfSkaene from './SelectAllOfSkaene';

const isWeb = Platform.OS === 'web';
const BORDER_WIDTH = 3;
const BORDER_RADIUS = 7;
const INPUT_FIELD_HEIGHT = 50;
const PADDING_LEFT = 16;
const LINE_HEIGHT_WEB = 45;

interface DestinationsProps {
  control: Control<FieldValues, any>;
  isLendingOutSelected: boolean;
  isChecked: boolean;
  isAllOfSkaeneSelected: { ticket: boolean; profile: boolean };
  onDestinationSearch: (param: string) => string[];
  setIsAllOfSkaeneSelected: (params: any) => void;
}

const getFormData = (i18n: any) => [
  {
    placeholder: i18n.t('placeholderFrom'),
    name: 'from',
    name_ticket: 'from_ticket',
    name_profile: 'from_profile',
  },
  {
    placeholder: i18n.t('placeholderTo'),
    name: 'from',
    name_ticket: 'to_ticket',
    name_profile: 'to_profile',
  },
];

const Destinations: React.FC<DestinationsProps> = ({
  control,
  isChecked,
  isLendingOutSelected,
  isAllOfSkaeneSelected,
  setIsAllOfSkaeneSelected,
  onDestinationSearch,
}) => {
  const i18n = useI18n();
  const formData = getFormData(i18n);
  const inputRefFrom = useRef(null);
  const inputRefTo = useRef(null);
  const inputRefs = [inputRefFrom, inputRefTo];
  const [searchData, setSearchData] = useState<string[] | never[]>([]);
  const theme = useTheme();
  const { settings } = theme;
  const { COLORS } = settings;
  const lineHeight = isWeb ? { lineHeight: LINE_HEIGHT_WEB } : {};

  const handleToggleState = useCallback(() => {
    setIsAllOfSkaeneSelected({
      ticket: isLendingOutSelected ? !isAllOfSkaeneSelected.ticket : isAllOfSkaeneSelected.ticket,
      profile: isLendingOutSelected
        ? isAllOfSkaeneSelected.profile
        : !isAllOfSkaeneSelected.profile,
    });
  }, [isAllOfSkaeneSelected, isLendingOutSelected, setIsAllOfSkaeneSelected]);

  return (
    <Wrapper ai="flex-start">
      <LabelAndDescription
        title={i18n.t('createDestinationsTitle')}
        description={i18n.t('createDestinationsDescription')}
      />
      <Margin marginTop="md" />
      <SelectAllOfSkaene isChecked={isChecked} handleToggleState={handleToggleState} />
      {!isChecked && (
        <>
          <Margin marginTop="md" />
          {formData.map(({ placeholder, name_ticket, name_profile }, i) => (
            <Controller
              key={`${placeholder}-${i}`}
              control={control}
              name={isLendingOutSelected ? name_ticket : name_profile}
              render={({ field: { onChange, value, onBlur } }) => {
                const isFirst = i === 0;

                return (
                  <>
                    <Wrapper
                      h={INPUT_FIELD_HEIGHT}
                      jc="center"
                      ai="flex-start"
                      style={{
                        borderBottomWidth: isFirst ? 0 : BORDER_WIDTH,
                        borderRightWidth: BORDER_WIDTH,
                        borderLeftWidth: BORDER_WIDTH,
                        borderTopWidth: BORDER_WIDTH,
                        borderColor: COLORS.BACKGROUND_LIGHT_ORANGE,
                        borderTopRightRadius: isFirst ? BORDER_RADIUS : 0,
                        borderTopLeftRadius: isFirst ? BORDER_RADIUS : 0,
                        borderBottomRightRadius: isFirst ? 0 : BORDER_RADIUS,
                        borderBottomLeftRadius: isFirst ? 0 : BORDER_RADIUS,
                      }}
                    >
                      <InputField
                        inputRef={inputRefs[i]}
                        hasBorder={false}
                        keyboardType="default"
                        placeholder={placeholder}
                        onBlur={onBlur}
                        onChange={async (v: string) => {
                          if (v.length >= 3) {
                            setSearchData(await onDestinationSearch(v));
                          } else if (searchData) {
                            setSearchData([]);
                          }

                          onChange(v);
                        }}
                        value={value}
                        style={{
                          paddingLeft: PADDING_LEFT,
                          width: '100%',
                          ...lineHeight,
                        }}
                      />
                    </Wrapper>
                    {(inputRefs as any)?.[i]?.current?.isFocused() && (
                      <Dropdown
                        data={searchData}
                        onPressDestination={updateValue => {
                          const derivedValue = updateValue.split(' ');
                          const optimizedValue =
                            derivedValue.length > 0
                              ? `${derivedValue[0]} ${derivedValue[1]}`
                              : derivedValue[0];

                          setSearchData([]);
                          onChange(updateValue);
                          (inputRefs as any)[i].current.setNativeProps({ text: optimizedValue });
                        }}
                      />
                    )}
                  </>
                );
              }}
            />
          ))}
        </>
      )}
    </Wrapper>
  );
};

export default Destinations;
