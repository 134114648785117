import React, { useMemo } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Margin } from '@lendticket/ui/components/atoms';
import { InputField, InputFieldWithIcon } from '@lendticket/ui/components/molecules';
import LoginOrCreateButtons from './LoginOrCreateButtons';
import { usePasswordValidation } from './hooks/usePasswordValidation';
import { useEmailValidation } from './hooks/useEmailValidation';
import { useI18n } from '@lendticket/app/hooks/i18n';
import type { FormActions } from './LoginOrCreateButtons';

const getFormData = (i18n: any) => [
  {
    placeholder: i18n.t('placeholderEmail'),
    svgName: 'email',
    name: 'email',
    type: 'email',
  },
  {
    placeholder: i18n.t('placeholderName'),
    svgName: 'userName',
    name: 'name',
    type: 'name',
  },
  {
    placeholder: i18n.t('placeholderPassword'),
    svgName: 'password',
    name: 'password',
    type: 'password',
  },
  {
    placeholder: i18n.t('placeholderConfirmPassword'),
    svgName: 'repeatPassword',
    name: 'repeatPassword',
    type: 'password',
  },
];

const CreateUserForm: React.FC<FormActions> = ({ actions }) => {
  const i18n = useI18n();
  const passwordValidation = usePasswordValidation();
  const emailValidation = useEmailValidation();
  const formData = useMemo(() => getFormData(i18n), [i18n]);
  const { control, handleSubmit } = useForm({ mode: 'onBlur' });

  const onSubmit = (data: any) => {
    /* Throw alerts and do callback */
    const isEmailValid = emailValidation(data);
    const isPasswordsValid = passwordValidation(data);

    if (isEmailValid && isPasswordsValid) {
      actions.create.action(data);
    }
  };

  return (
    <>
      <Margin marginTop="md" />
      {formData.map(({ placeholder, svgName, name, type }, i) => (
        <InputFieldWithIcon key={`${placeholder}-${i}`} svgName={svgName}>
          <Controller
            control={control}
            name={name}
            render={({ field: { onChange, value, onBlur } }) => {
              let keyboardType: any = 'default';
              const isInputFieldPassword = type === 'password';
              const isInputFieldEmail = type === 'email';

              if (isInputFieldEmail) {
                keyboardType = 'email-address';
              }

              return (
                <InputField
                  hasFullWidth
                  hasBorder
                  hasPaddingLeft={false}
                  multiline={false}
                  isPassword={isInputFieldPassword}
                  keyboardType={keyboardType}
                  placeholder={placeholder}
                  onKeyDown={handleSubmit(onSubmit)}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                />
              );
            }}
          />
        </InputFieldWithIcon>
      ))}
      <LoginOrCreateButtons
        type="create"
        actions={actions}
        onSubmit={handleSubmit(onSubmit)}
        method="create"
      />
    </>
  );
};

export default CreateUserForm;
