import { useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { getTimestamp } from '@lendticket/app/helpers/strings';
import api from '@lendticket/app/reducers/slices/api';
import { alertMessage } from '@lendticket/app/helpers/alertMessage';
import { useAppSelector } from '@lendticket/app/hooks/redux';
import { userSelector } from '@lendticket/app/reducers/slices/user';
import { shallowEqual } from 'react-redux';

export const useUpdateChat = () => {
  const [updateChatByChatId] = api.useUpdateChatByChatIdMutation();
  const user = useAppSelector(userSelector, shallowEqual);

  return useCallback(
    async ({ chatId, chatDetails, message, callback }: any) => {
      const messageId = uuidv4();
      const { id: userId, name } = user ?? {};

      updateChatByChatId({
        chatId,
        chatDetails,
        message: {
          id: messageId,
          from: userId,
          name,
          text: message,
          timestamp: getTimestamp(),
          createdAt: new Date().getTime(),
        },
      })
        .then((res: any) => {
          callback(res.data);
        })
        .catch(error => {
          alertMessage(error);
        });
    },
    [updateChatByChatId, user],
  );
};
