import React from 'react';
import { View } from 'react-native';
import LottieView from 'lottie-react-native';
import moment from 'moment';

const SeasonalLottie = () => {
  const today = moment();
  const christmasEnd = moment('2024-12-25'); // Adjust the year dynamically if needed
  const newYearEnd = moment('2025-01-02'); // Adjust the year dynamically if needed
  const isNewYear = today.isBetween(christmasEnd, newYearEnd, null, '[)');
  let animationSource = null;

  if (today.isSameOrBefore(christmasEnd)) {
    animationSource = require('../../../../ui/assets/lottie/Christmas.json');
  } else if (isNewYear) {
    animationSource = require('../../../../ui/assets/lottie/NewYear.json');
  }

  if (!animationSource) {
    return null; // Render nothing if no animation is needed
  }

  return (
    <View
      style={{
        zIndex: -1,
        opacity: isNewYear ? 1 : 0.35,
        position: 'absolute',
        width: '100%',
        height: '100%',
      }}
    >
      <LottieView
        source={animationSource}
        style={{ width: '100%', height: '100%' }}
        autoPlay
        loop={isNewYear}
      />
    </View>
  );
};

export default SeasonalLottie;
