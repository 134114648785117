import { useTranslation, UseTranslationResponse } from 'react-i18next';

const { DEFAULT_NAMESPACE } = require('@lendticket/app/helpers/i18n');

const defaultNamespaces = [DEFAULT_NAMESPACE];

export const useI18n: (namespaces?: string[]) => UseTranslationResponse<string[], undefined> = (
  namespaces: string[] = [],
): UseTranslationResponse<string[], undefined> =>
  useTranslation(namespaces.concat(defaultNamespaces));
