import React, { useCallback } from 'react';
import { alertMessage } from '@lendticket/app/helpers/alertMessage';
import api from '@lendticket/app/reducers/slices/api';
import { useAppDispatch, useAppSelector } from '@lendticket/app/hooks/redux';
import { useCreateTickets } from './useCreateTickets';
import { useCreateProfile } from './useCreateProfile';
import { userSelector } from '@lendticket/app/reducers/slices/user';
import { shallowEqual } from 'react-redux';
import { useI18n } from '@lendticket/app/hooks/i18n';

type SelectedPeriodsType =
  | {
      periodFrom: string;
      periodStart: string;
    }[]
  | never[];

interface onSubmitParams {
  data: any;
  isLendingOutSelected: boolean;
  isAllOfSkaeneSelected: boolean;
  selectedPeriods: SelectedPeriodsType;
  ticketTypesAndPayment: any;
  multipleDataSets: any[];
  resetFormStates: () => void;
  setSelectedPeriods: React.Dispatch<React.SetStateAction<{ ticket: never[]; profile: never[] }>>;
  setHasCreations: React.Dispatch<React.SetStateAction<undefined | boolean>>;
}

const reduceString = (str: string) => {
  const derivedValue = str.split(' ');

  const optimizedValue =
    derivedValue.length > 1 ? `${derivedValue[0]} ${derivedValue[1]}` : derivedValue[0];

  return optimizedValue;
};

export const useOnFormSubmit = () => {
  const i18n = useI18n();
  const { getUserByAttribute } = api.endpoints;
  const user = useAppSelector(userSelector, shallowEqual);
  const dispatch = useAppDispatch();
  const createTickets = useCreateTickets();
  const createProfile = useCreateProfile();

  return useCallback(
    async ({
      data,
      selectedPeriods,
      isLendingOutSelected,
      isAllOfSkaeneSelected,
      multipleDataSets,
      ticketTypesAndPayment,
      setHasCreations,
      resetFormStates,
    }: onSubmitParams) => {
      const derivedData = Object.keys(data).reduce((obj, item, i) => {
        if (isLendingOutSelected && item.includes('_ticket')) {
          obj = {
            ...obj,
            [item.replace('_ticket', '')]: Object.values(data)[i],
          };
        } else if (!isLendingOutSelected && item.includes('_profile')) {
          obj = {
            ...obj,
            [item.replace('_profile', '')]: Object.values(data)[i],
          };
        }

        if (isAllOfSkaeneSelected) {
          delete obj.from;
          delete obj.to;
        }

        return obj;
      }, {} as any);

      if (isLendingOutSelected && Object.values(derivedData).some(formData => !formData)) {
        alertMessage(i18n.t('alertFieldsEmpty'));
      } else if (
        !isLendingOutSelected &&
        Object.values(derivedData)
          .filter((_, i) => i !== Object.keys(derivedData).findIndex(name => name === 'provider'))
          .some(formData => !formData)
      ) {
        alertMessage(i18n.t('alertFieldsEmpty'));
      } else if (derivedData.lendTime.length === 0) {
        alertMessage(i18n.t('alertNoLendoutTime'));
      } else if (derivedData.price.length === 0) {
        alertMessage(i18n.t('alertNoPrice'));
      } else if (
        selectedPeriods.length === 0 ||
        typeof selectedPeriods[0].periodStart === 'undefined'
      ) {
        alertMessage(i18n.t('alertPleaseSelectOneOrMultipleDates'));
      } else {
        const { id: userId } = user ?? {};
        const { refetch } = dispatch(getUserByAttribute.initiate(userId));
        const { data: latestUserData } = await refetch();
        const { user: latestUser } = latestUserData ?? {};
        const derivedMultipleDataSets = [...multipleDataSets, derivedData];
        resetFormStates();

        if (isLendingOutSelected) {
          createTickets({
            selectedPeriods,
            isAllOfSkaeneSelected,
            multipleDataSets: derivedMultipleDataSets,
            data: derivedData,
            ticketTypesAndPayment: ticketTypesAndPayment.ticket,
            latestUser,
            reduceString,
            setHasCreations,
          });
        } else {
          createProfile({
            selectedPeriods,
            isAllOfSkaeneSelected,
            multipleDataSets: derivedMultipleDataSets,
            data: derivedData,
            ticketTypesAndPayment: ticketTypesAndPayment.profile,
            latestUser,
            reduceString,
            setHasCreations,
          });
        }
      }
    },
    [user, i18n, dispatch, getUserByAttribute, createTickets, createProfile],
  );
};
