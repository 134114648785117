import { useAppDispatch } from '@lendticket/app/hooks/redux';
import { useCallback } from 'react';
import api from '@lendticket/app/reducers/slices/api';

export const useOnDestinationSearch = () => {
  const dispatch = useAppDispatch();

  return useCallback(
    async (param: string) => {
      const { data } = await dispatch(api.endpoints.getDestinationsBySearchParams.initiate(param));

      return data;
    },
    [dispatch],
  );
};
