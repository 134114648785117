import React, { useCallback, useContext } from 'react';
import { jwtDecode } from 'jwt-decode';
import { NavigationContainer } from '@react-navigation/native';
import {
  navigationRef,
  getPathFromState,
  getStateFromPath,
} from '@lendticket/app/helpers/navigation/config';
import linking from '@lendticket/app/helpers/navigation/linking';
import * as routeNames from '@lendticket/app/constants/routeNames';
import Routes from './routes';
import persisted from '@lendticket/app/helpers/storage/persisted';
import { useAppSelector } from '@lendticket/app/hooks/redux';
import { userActions, userSelector } from '@lendticket/app/reducers/slices/user';
import { useAppDispatch } from '@lendticket/app/helpers/redux/store';
import { shallowEqual } from 'react-redux';
import { LoadingContext, ModalContext } from '@lendticket/app/contexts';
import { useI18n } from '@lendticket/app/hooks/i18n';

const linkingConfig = {
  ...linking,
  getPathFromState,
  getStateFromPath,
};

const allowedUnauthorizedRoutes = [
  routeNames.PRIVACY_POLICY,
  routeNames.ONBOARDING,
  routeNames.FIND_TICKETS_AND_PROFILES,
  routeNames.LOGIN,
  routeNames.FORGOT_PASSWORD,
  routeNames.RESET_PASSWORD,
  routeNames.PROVIDE_EMAIL,
  routeNames.EMAIL_SENT,
  routeNames.LOADING,
  routeNames.UNAUTHORIZED,
  routeNames.REDIRECT,
];

const AppRouter: React.FC = () => {
  const i18n = useI18n();
  const { setModalState } = useContext(ModalContext);
  const { isLoading, hasBackgroundColor } = useContext(LoadingContext);
  const dispatch = useAppDispatch();
  const user = useAppSelector(userSelector, shallowEqual);

  const handleUnauthorizedUser = useCallback(
    async ({ routeName }: any) => {
      /* NOTE: User is allowed to enter the chat and profiles overview, but anywhere
      else a modal should appear. The alternative for all other routes is to handle the
      errors in a redux middleware */
      if (!user && !allowedUnauthorizedRoutes.includes(routeName)) {
        setModalState({
          visible: true,
          title: i18n.t('loginOrCreateUser'),
          onConfirmButtonText: i18n.t('login'),
          onCloseButtonText: i18n.t('goBack'),
          message: i18n.t('inOrderToUseThisFeature'),
          type: 'unauthorized',
        });
      }
    },
    [i18n, setModalState, user],
  );

  const handleRedirection = useCallback(
    async ({ navigate, routeName, routeParams }: any) => {
      const isRouteNameResetPassword = routeNames.RESET_PASSWORD === routeName;

      if (routeName === routeNames.REDIRECT) {
        navigate(routeNames.REDIRECT, routeParams);
      } else if (isRouteNameResetPassword || routeName === routeNames.FORGOT_PASSWORD) {
        navigate(
          isRouteNameResetPassword ? routeNames.RESET_PASSWORD : routeNames.FORGOT_PASSWORD,
          routeParams,
        );
      } else if (routeName === routeNames.PRIVACY_POLICY) {
        navigate(routeNames.PRIVACY_POLICY);
      } else if (!user) {
        /* NOTE: Hard refresh handler */
        const token = await persisted.getItem('authToken');

        if (token) {
          const decodedToken = jwtDecode(token);
          dispatch(userActions.addUser(decodedToken));
          navigate(routeNames.DRAWER_AND_TABBAR, {
            screen: routeNames.TABS,
            params: { screen: routeNames.MESSAGES },
          });
        } else if (routeName !== routeNames.ONBOARDING) {
          navigate(routeNames.ONBOARDING);
        }
      }
    },
    [user, dispatch],
  );

  return (
    <NavigationContainer
      ref={navigationRef}
      linking={linkingConfig}
      onStateChange={() => {
        handleUnauthorizedUser({
          navigate: navigationRef.current?.navigate,
          reset: navigationRef.current?.reset,
          routeName: navigationRef.current?.getCurrentRoute()?.name,
        });
      }}
      onReady={() => {
        /* NOTE: The app is initiated with loading true, which is why we turn it off here.
        Further initialization can be found in the helpers/redux/store */
        isLoading.value = false;
        hasBackgroundColor.value = false;

        handleRedirection({
          navigate: navigationRef.current?.navigate,
          reset: navigationRef.current?.reset,
          routeName: navigationRef.current?.getCurrentRoute()?.name,
          routeParams: navigationRef.current?.getCurrentRoute()?.params,
        });
      }}
    >
      <Routes />
    </NavigationContainer>
  );
};

export default AppRouter;
