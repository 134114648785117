import React from 'react';
import { FlatList, Platform } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import styled, { useTheme } from 'styled-components/native';
import { BottomNavigationBar, Appearance } from '@lendticket/ui/components/organisms';
import { Wrapper, MarginColumn, Margin, Text } from '@lendticket/ui/components/atoms';
import {
  FullWrapperHeight,
  Gradient,
  Card,
  NoContent,
  Icon,
} from '@lendticket/ui/components/molecules';
import { useI18n } from '@lendticket/app/hooks/i18n';

const isWeb = Platform.OS === 'web';
const ICON_DIMENSIONS = 20;
const ANDROID_FADING_EDGE_LENGTH = 16;

const TouchableOpacity = styled.TouchableOpacity``;
const Seperator = styled.View`
  margin-top: ${({ theme }) => theme.settings.SPACINGS.md}px;
`;

export interface ChatParams {
  id?: string;
  name?: string;
  createdAt?: string;
  destinationFrom?: string;
  destinationTo?: string;
  img?: string;
  latestMessageDate?: string;
  latestMessage?: string;
  chatParticipantIds?: string[];
  userId?: string;
  messages?: {
    text: string;
    chatId: string;
    userId: string;
    user: string;
    timestamp: string;
  }[];
}

interface MessagesPageProps {
  chats: ChatParams[];
  title: string;
  newChatIds: string[];
  hasChats: boolean;
  hiddenCardText: string;
  unAnsweredChatIds?: string[];
  isInitiated?: boolean;
  currentUserName?: string;
  updateChatMessages: () => void;
  onEndReached: () => void;
  onPressAction: (props: any) => void;
  onSwipeStartChat: (id: string) => void;
}

const MessagesPage: React.FC<MessagesPageProps> = ({
  onPressAction,
  onSwipeStartChat,
  onEndReached,
  updateChatMessages,
  chats,
  currentUserName,
  isInitiated,
  hasChats,
  newChatIds,
  hiddenCardText,
  unAnsweredChatIds,
}) => {
  const i18n = useI18n();
  const { deviceSizes, settings } = useTheme();
  const { SPACINGS, CONSTANTS, COLORS } = settings;
  const { isMobile } = deviceSizes;
  const { DESKTOP_TABLET_WIDTH } = CONSTANTS;

  return (
    <SafeAreaView>
      <Gradient />
      <FullWrapperHeight hasBottomTabBar={!isWeb} footer={isWeb && <BottomNavigationBar />}>
        <Wrapper f={1} w={isMobile ? undefined : DESKTOP_TABLET_WIDTH}>
          <Wrapper f={1} fd="row" ai="flex-start" mb={SPACINGS.md}>
            <MarginColumn />
            <Wrapper f={1} fd="column" ai="flex-start" style={{ alignSelf: 'stretch' }}>
              {!isInitiated ? (
                <Wrapper jc="center" ai="center">
                  <Text fontType={isWeb ? 'h5' : 'body'} style={{ color: 'white' }}>
                    {i18n.t('pleaseWaitFetchingChats')}
                  </Text>
                </Wrapper>
              ) : (
                <>
                  <Wrapper jc="center" ai="center">
                    <Text fontType="h5" style={{ color: COLORS.WHITE, textAlign: 'center' }}>
                      {i18n.t('slideToDeleteChat')}
                    </Text>
                  </Wrapper>

                  <Wrapper style={{ marginTop: 6, marginBottom: 16 }}>
                    <TouchableOpacity
                      onPress={() => updateChatMessages()}
                      style={{
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Text fontType={isWeb ? 'h5' : 'body'} style={{ color: COLORS.WHITE }}>
                        {i18n.t('updateOverview')}
                      </Text>
                      <Margin marginLeft="s" />
                      <Icon
                        name="refresh"
                        fill={COLORS.WHITE}
                        height={ICON_DIMENSIONS}
                        width={ICON_DIMENSIONS}
                      />
                    </TouchableOpacity>
                  </Wrapper>
                </>
              )}
              {!hasChats && <NoContent text={i18n.t('noChatMessages')} />}
              {hasChats && (
                <FlatList
                  onEndReached={onEndReached}
                  fadingEdgeLength={ANDROID_FADING_EDGE_LENGTH}
                  style={{ width: '100%' }}
                  refreshing={false}
                  onRefresh={() => updateChatMessages()}
                  data={chats}
                  ItemSeparatorComponent={Seperator}
                  renderItem={({ item, index }) =>
                    newChatIds.includes(item.id) ? (
                      <Appearance delay={(index + 1) * 100}>
                        <Card
                          isMessageOverview
                          currentUserName={currentUserName}
                          onPressAction={() =>
                            onPressAction({
                              ticketId: item.ticketId,
                              profileId: item.profileId,
                              chatId: item.id,
                              chatOwnerId: item.chatOwnerId,
                            })
                          }
                          onSwipeAction={() => onSwipeStartChat(item.ticketId, item.id)}
                          hiddenCardText={hiddenCardText}
                          isMessageNew={unAnsweredChatIds?.includes(item.id)}
                          {...item}
                        />
                      </Appearance>
                    ) : (
                      <Card
                        isMessageOverview
                        currentUserName={currentUserName}
                        onPressAction={() =>
                          onPressAction({
                            ticketId: item.ticketId,
                            chatId: item.id,
                            profileId: item.profileId,
                            chatOwnerId: item.chatOwnerId,
                          })
                        }
                        onSwipeAction={() => onSwipeStartChat(item.ticketId, item.id)}
                        hiddenCardText={hiddenCardText}
                        isMessageNew={unAnsweredChatIds?.includes(item.id)}
                        {...item}
                      />
                    )
                  }
                  keyExtractor={({ createdAt }, i) => `${createdAt}-${i}`}
                />
              )}
            </Wrapper>
            <MarginColumn />
          </Wrapper>
        </Wrapper>
      </FullWrapperHeight>
    </SafeAreaView>
  );
};

export default MessagesPage;
