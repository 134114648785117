import { useCallback } from 'react';
import { Linking } from 'react-native';
import { usePopup } from '../../../hooks/usePopup';
import { isWeb } from '@lendticket/app/helpers/platform';

interface useGoogleSigninParams {
  url: string;
  onSuccess: (data: any) => void;
  onFail?: (data: any) => void;
}

export const useSocialSignin = () => {
  const popup = usePopup();

  return useCallback(
    async ({ url, onSuccess, onFail }: useGoogleSigninParams) => {
      if (!isWeb) {
        await Linking.openURL(url);
      } else {
        popup({
          url,
          onSuccess: (data: any) => {
            onSuccess(data);
          },
          onFail: (data: any) => {
            if (onFail) {
              onFail(data);
            }
          },
        });
      }

      return;
    },
    [popup],
  );
};
