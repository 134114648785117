import React, { useMemo } from 'react';
import { ContentPage } from '@lendticket/ui/components/templates';
import { useI18n } from '@lendticket/app/hooks/i18n';

const getFeatures = (i18n: any) => [
  {
    title: i18n.t('supportFeatureTitle'),
    svgName: 'support',
    text: i18n.t('supportFeatureText'),
  },
];

const Support: React.FC = () => {
  const i18n = useI18n();
  const features = useMemo(() => getFeatures(i18n), [i18n]);

  return <ContentPage features={features} />;
};

export default Support;
