import React from 'react';
import { useSharedValue } from 'react-native-reanimated';
import { FieldValues, Control } from 'react-hook-form';
import { Platform } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useTheme } from 'styled-components/native';
import {
  SwiperButton,
  CreatePageForm,
  BottomNavigationBar,
} from '@lendticket/ui/components/organisms';
import { Wrapper, MarginColumn, Margin } from '@lendticket/ui/components/atoms';
import { FullWrapperHeight, Gradient, FooterCreatePage } from '@lendticket/ui/components/molecules';
import { useI18n } from '@lendticket/app/hooks/i18n';

const isWeb = Platform.OS === 'web';
const isAndroid = Platform.OS === 'android';
interface CreatePageProps {
  control: Control<FieldValues, any>;
  selectedPeriods: any[];
  hasCreations?: boolean;
  datePickerDates: any;
  scrollViewRef: any;
  toggleTicketTypesAndPayment: any;
  isAllOfSkaeneSelected: { ticket: boolean; profile: boolean };
  setIsAllOfSkaeneSelected: () => void;
  setToggleTicketTypesAndPayment: (updateParams: any) => void;
  onDestinationSearch: (param: string) => void;
  updateSelectedPeriods: (selectedPeriod: any[]) => void;
  setDatePickerDates: (updateParams: any) => void;
  onPressPublish: () => void;
  onPressEdit: () => void;
  swiperCallback: () => void;
}

const CreatePage: React.FC<CreatePageProps> = ({
  selectedPeriods,
  control,
  hasCreations,
  datePickerDates,
  scrollViewRef,
  toggleTicketTypesAndPayment,
  isAllOfSkaeneSelected,
  setIsAllOfSkaeneSelected,
  setToggleTicketTypesAndPayment,
  setDatePickerDates,
  onDestinationSearch,
  onPressPublish,
  onPressEdit,
  updateSelectedPeriods,
  swiperCallback,
}) => {
  const i18n = useI18n();
  const { deviceSizes, settings } = useTheme();
  const translationX = useSharedValue(0);
  const swiperWidth = useSharedValue(0);

  const { CONSTANTS } = settings;
  const { isMobile } = deviceSizes;
  const { DESKTOP_TABLET_WIDTH } = CONSTANTS;

  return (
    <SafeAreaView>
      <Gradient />
      <FullWrapperHeight
        hasBottomTabBar={!isWeb}
        footer={
          <Wrapper fd="column" w={isMobile ? undefined : DESKTOP_TABLET_WIDTH}>
            <FooterCreatePage
              hasCreations={hasCreations}
              onPressPublish={onPressPublish}
              onPressEdit={onPressEdit}
            />
            {isWeb && <BottomNavigationBar />}
          </Wrapper>
        }
      >
        <Wrapper f={1} w={isMobile ? undefined : DESKTOP_TABLET_WIDTH}>
          <Wrapper fd="row" ai="flex-start">
            <MarginColumn />
            <Wrapper f={1} fd="column" jc="center" ai="flex-start">
              <SwiperButton
                textLeft={i18n.t('lendOut')}
                textRight={i18n.t('lend')}
                swiperCallback={swiperCallback}
                animatedValues={{
                  translationX,
                  swiperWidth,
                }}
              />
            </Wrapper>
            <MarginColumn />
          </Wrapper>
          <CreatePageForm
            control={control}
            scrollViewRef={scrollViewRef}
            animatedValues={{ translationX, swiperWidth }}
            datePickerDates={datePickerDates}
            toggleTicketTypesAndPayment={toggleTicketTypesAndPayment}
            selectedPeriods={selectedPeriods}
            isAllOfSkaeneSelected={isAllOfSkaeneSelected}
            setIsAllOfSkaeneSelected={setIsAllOfSkaeneSelected}
            setToggleTicketTypesAndPayment={setToggleTicketTypesAndPayment}
            setDatePickerDates={setDatePickerDates}
            onDestinationSearch={onDestinationSearch}
            updateSelectedPeriods={updateSelectedPeriods}
          />
          <Margin marginBottom={isAndroid ? 'md' : ''} />
        </Wrapper>
      </FullWrapperHeight>
    </SafeAreaView>
  );
};

export default CreatePage;
