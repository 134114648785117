import { isWeb } from '@lendticket/app/helpers/platform';

export const getIsWebView = () => {
  if (!isWeb) {
    return false;
  }

  var standalone = window.navigator.standalone,
    userAgent = window.navigator.userAgent.toLowerCase(),
    safari = /safari/.test(userAgent),
    ios = /iphone|ipod|ipad/.test(userAgent);

  if (ios) {
    if (!standalone && safari) {
      return false;
    } else if (standalone && !safari) {
      return false;
    } else if (!standalone && !safari) {
      return true;
    }
  } else {
    return false;
  }
};
