import React, { useMemo } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Margin } from '@lendticket/ui/components/atoms';
import { InputField, InputFieldWithIcon } from '@lendticket/ui/components/molecules';
import LoginOrCreateButtons from './LoginOrCreateButtons';
import { useEmailValidation } from './hooks/useEmailValidation';
import { alertMessage } from '@lendticket/app/helpers/alertMessage';
import { useI18n } from '@lendticket/app/hooks/i18n';
import type { FormActions } from './LoginOrCreateButtons';

const getFormData = (i18n: any) => [
  {
    placeholder: i18n.t('placeholderEmail'),
    svgName: 'email',
    name: 'email',
    type: 'email',
  },

  {
    placeholder: i18n.t('placeholderPassword'),
    svgName: 'password',
    name: 'password',
    type: 'password',
  },
];

const LoginForm: React.FC<FormActions> = ({ actions }) => {
  const i18n = useI18n();
  const emailValidation = useEmailValidation();
  const { control, handleSubmit } = useForm({ mode: 'onBlur' });
  const formData = useMemo(() => getFormData(i18n), [i18n]);

  const onSubmit = (data: any) => {
    const isEmailValid = emailValidation(data);

    if (data?.password === '' || !data?.password) {
      alertMessage(i18n.t('alertPleaseProvidePassword'));
    } else if (isEmailValid) {
      actions.login.action(data);
    }
  };

  return (
    <>
      <Margin marginTop="md" />
      {formData.map(({ placeholder, svgName, type, name }, i) => (
        <InputFieldWithIcon key={`${placeholder}-${i}`} svgName={svgName}>
          <Controller
            control={control}
            name={name}
            render={({ field: { onChange, value, onBlur } }) => {
              let keyboardType: any = 'default';
              const isInputFieldPassword = type === 'password';
              const isInputFieldEmail = type === 'email';

              if (isInputFieldEmail) {
                keyboardType = 'email-address';
              }

              return (
                <InputField
                  hasFullWidth
                  hasBorder
                  hasPaddingLeft={false}
                  multiline={false}
                  isPassword={isInputFieldPassword}
                  keyboardType={keyboardType}
                  placeholder={placeholder}
                  onKeyDown={handleSubmit(onSubmit)}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                />
              );
            }}
          />
        </InputFieldWithIcon>
      ))}
      <LoginOrCreateButtons type="login" actions={actions} onSubmit={handleSubmit(onSubmit)} />
    </>
  );
};

export default LoginForm;
