import { Text, Wrapper } from '@lendticket/ui/components/atoms';
import { LoadingPage } from '@lendticket/ui/components/templates';
import { useI18n } from '@lendticket/app/hooks/i18n';
import { useAppDispatch, useAppSelector } from '@lendticket/app/hooks/redux';
import React from 'react';
import { useSharedValue, withTiming, useAnimatedReaction } from 'react-native-reanimated';
import { shallowEqual } from 'react-redux';
import { loadingActions, loadingSelector } from '@lendticket/app/reducers/slices/loading';
import styled, { useTheme } from 'styled-components/native';
import { LoadingProviderWrapper } from '../../contexts';

interface Props {
  children: React.ReactNode;
}

const TouchableOpacity = styled.TouchableOpacity`
  margin-top: ${({ theme }) => theme.settings.SPACINGS.md}px;
  border-bottom-width: 1px;
  border-bottom-color: white;
`;

const LoadingProvider: React.FC<Props> = ({ children }) => {
  const { settings } = useTheme();
  const i18n = useI18n();
  const { COLORS } = settings;
  const loading = useAppSelector(loadingSelector, shallowEqual);
  const isLoading = useSharedValue(true);
  const hasBackgroundColor = useSharedValue(true);
  const translationY = useSharedValue(0);
  const display = useSharedValue('none');
  const dispatch = useAppDispatch();

  useAnimatedReaction(
    () => ({ isLoading }),
    () => {
      if (isLoading.value !== loading.isLoading) {
        isLoading.value = loading.isLoading;
        hasBackgroundColor.value = loading.hasBackgroundColor;
        display.value = loading.isLoading ? 'none' : 'flex';
        translationY.value = loading.isLoading ? 5 : withTiming(0, { duration: 250 });
      }
    },
    [loading, isLoading],
  );

  return (
    <LoadingProviderWrapper value={{ isLoading, hasBackgroundColor }}>
      <LoadingPage
        isLoading={isLoading}
        indicator={loading?.indicator}
        hasBackgroundColor={hasBackgroundColor}
      >
        <Wrapper style={{ position: 'absolute', top: 0 }}>
          <Text fontType="body" style={{ color: COLORS.BACKGROUND_EXTRA_LIGHT_ORANGE }}>
            {loading?.text ?? i18n.t('pleaseWait')}
          </Text>
          {loading?.isCancelable && (
            <TouchableOpacity
              onPress={() => dispatch(loadingActions.setLoadingContent({ isLoading: false }))}
            >
              <Text fontType="body" style={{ color: COLORS.BACKGROUND_EXTRA_LIGHT_ORANGE }}>
                (Stop loading)
              </Text>
            </TouchableOpacity>
          )}
        </Wrapper>
      </LoadingPage>
      {children}
    </LoadingProviderWrapper>
  );
};

export default LoadingProvider;
