import React from 'react';
import { createDrawerNavigator } from '@react-navigation/drawer';
import * as routeNames from '@lendticket/app/constants/routeNames';
import { isWeb } from '@lendticket/app/helpers/platform';
import { navigationRef } from '@lendticket/app/helpers/navigation/config';
import Search from '@lendticket/app/areas/Search';
import TabBar from './TabBar';

const DrawerComponent = createDrawerNavigator();

const Drawer: React.FC = () => (
  <DrawerComponent.Navigator
    initialRouteName={routeNames.TABS}
    drawerContent={(props: any) => <Search {...props} />}
    screenOptions={{
      swipeEnabled:
        navigationRef.current?.getCurrentRoute()?.name === routeNames.FIND_TICKETS_AND_PROFILES,
      drawerType: isWeb ? 'back' : 'front', // Bug on web with flickering effect
      headerShown: false,
    }}
  >
    <DrawerComponent.Screen name={routeNames.TABS} component={TabBar} />
  </DrawerComponent.Navigator>
);

export default Drawer;
